import {
  Action,
  createFeatureSelector,
  createReducer,
  createSelector,
  on,
} from "@ngrx/store";
import { getGradeState } from "../../grades/store/grade.reducers";
import { getStudents } from "../../students/store/student.reducers";
import { Payment, PaymentLedger } from "../Payments.model";
import { months } from "../services/payment.ledger.service";
import * as fromPaymentActions from "./payment.actions";

export interface PaymentState {
  studentId: string;
  ledger: PaymentLedger[];
  registrationPaid: boolean;
  totalPaid: number;
  err: any | undefined;
}

const initial: PaymentState = {
  studentId: "",
  registrationPaid: false,
  ledger: [],
  totalPaid: 0,
  err: undefined,
};

const getpaymentLedger = (payments: Payment[]) => {
  const payments_history = payments.filter((a) => a.type !== "r_fees");

  return months.map((month) => {
    if (payments_history.length > 0) {
      return {
        ...payments_history?.filter((a) => a.month === month.index)[0],
        month,
      } as PaymentLedger;
    }
    return { ...[], month };
  });
};
const registrationPaid = (payments: Payment[]) => {
  const payments_history = payments.filter((a) => a.type === "r_fees");
  return payments_history.length > 0 ? true : false;
};
const getTotalpaid = (payments: any[]) => {
  if (payments.length > 0) {
    var amountsPaid = payments.map(({ amount }) => amount);
    //console.log(payments);
    const totpaid = amountsPaid.reduce((pay, val) => pay + val) as number;
    return totpaid;
  }
  return 0;
};
const _paymentReducer = createReducer(
  initial,
  on(
    fromPaymentActions.loadPaymentHistorySuccess,
    (state, { Payments, studentId }) => ({
      ...state,
      studentId: studentId,
      ledger: getpaymentLedger(Payments) as PaymentLedger[],
      registrationPaid: registrationPaid(Payments),
      totalPaid: getTotalpaid(Payments),
    })
  ),
  on(fromPaymentActions.loadPaymentHistoryFailed, (state, errContainer) => ({
    ...state,
    err: errContainer.err,
  }))
);

export function PaymentReducer(state: any | undefined, action: Action) {
  return _paymentReducer(state, action);
}

export const getPaymentState = createFeatureSelector<PaymentState>("payments");

export const getStudentPaymentDetails = createSelector(
  getPaymentState,
  getGradeState,
  getStudents,
  (paymentstate, gradestate, studentstate) => {
    const selectedstudentId = paymentstate.studentId;
      const selectedStudent = studentstate.filter((s) => s.id === selectedstudentId)[0];
      const selectGrade = gradestate.grades.filter(
        (a) =>
          a.name.toLocaleLowerCase() ==
          selectedStudent.grade.toLocaleLowerCase()
      )[0];
      var registrationfee =  selectGrade?.registration ?? 0
      var startMonth = getstartmonth(selectedStudent?.startdate);
      const yearlyTotal = getyearlypayment(selectGrade?.fees, startMonth);
      const arrears =
        getarrears(selectGrade?.fees, paymentstate?.totalPaid, startMonth) -
        registrationfee;
       
      const studentstartMonth = startMonth + 1;
      return {
        paymentstate,
        selectedStudent,
        selectGrade,
        yearlyTotal,
        arrears,
        studentstartMonth,
      };
  }
);

const getstartmonth = (startdate: Date | undefined) => {
  if (startdate !== undefined) return new Date(startdate).getMonth();
  return -1
};
const getarrears = (
  fee: number | undefined,
  totalpaid: number,
  startMonth: number
) => {
  if (fee !== undefined) {
    const todatemonth = new Date().getMonth() + 1 - startMonth;
    const todatefee = fee * todatemonth;
    const arrears = totalpaid - todatefee;
    return arrears;
  }
  return 0;
};
const getyearlypayment = (fees: number | undefined, startmonth: number) => {
  if (fees !== undefined) return fees * months.filter((a) => a.index > startmonth).length;
  return 0
};
