<form class="form-control grid col-2  place-content-baseline" [formGroup]="formfilter" (ngSubmit)="filterStudent()">
    <div class="form-input">
        <input placeholder="Filter Student" formControlName="student" autocomplete="off" list="student-list" name="students" />
        <datalist id="student-list">
            <option *ngFor="let student of  students"  class="options-list"
                                            value="{{student.id}}">
                                            {{student.name}}
                {{student.surname}}</option>
        </datalist>
    </div>
    <button style="margin: 0;" type="submit">Filter</button>
</form>