import { Component, Input, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subscription, take, tap } from "rxjs";
import { NotifiyService } from "src/app/common/components/notifications/notifiy.service";
import {
  AuthService,
} from "../../service/auth.service";

@Component({
  selector: "app-user-login",
  templateUrl: "./use-login.component.html",
  styleUrls: ["./use-login.component.scss"],
})
export class UseLoginComponent implements OnInit {
  loginform = new FormGroup({
    username: new FormControl("", [Validators.required, Validators.email]),
    password: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
    ]),
  });
  constructor(
    private router: Router,
    public authService: AuthService,
    private route: ActivatedRoute,
    private notificationService: NotifiyService
  ) {}

  ngOnInit(): void {
    const login = this.authService?.isloggedIn$.pipe(take(1));
    if (login) this.router.navigate(["/home"]);
  }
  subscription = new Subscription();
 
  onSubmit() {
    if (this.loginform.valid) {
      const { username, password } = this.loginform.getRawValue();

      const serviceresponse = this.authService?.authenticate(
        username ?? "",
        password ?? ""
      );
      this.subscription=this.authService?.user$.subscribe((next) => {
        if (next !== null || next !== undefined) {
          if (next?.id.length !== 0) {
            this.router.navigate(["/home"]);
          }
        }
      });
    }
  }
}
