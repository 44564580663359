import { Injectable } from "@angular/core";
import PocketBase from "pocketbase";
import { async, from, observable, Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
import { NotifiyService } from "../components/notifications/notifiy.service";
import { LoaderService } from "./loader.service";

@Injectable({
  providedIn: "root",
})
export class DatabaseService {
  api = environment.apiEndpoint;
  private client = new PocketBase(this.api);

  constructor(
    private loaderservice: LoaderService,
    private notifyService: NotifiyService
  ) {}

  getactiveUser = () => {
    return this.client.authStore.model;
  };

  getone(record: string, id: any): Observable<any> {
    try {
      return new Observable((observer) => {
        this.client.records
          .getOne(record, id)
          .then((record) => {
            observer.next(record);
            observer.complete();
          })
          .catch((err) => observer.error(err));
      });
    } catch (err) {
      throw err as errorresponse;
    }
  }
  getfile(record: any, filename: string, params: any): Observable<any> {
    try {
      const url = this.client.records.getFileUrl(record, filename, params);

      return of(url);
    } catch (err) {
      throw err;
    }
  }
  public getclient(): PocketBase {
    return this.client;
  }
  public async create(record: string, payload: any) {
    try {
      return await this.client.records.create(record, payload);
    } catch (err) {
      throw err;
    }
  }
  public async update(record: string, id: any, payload: any) {
    try {
      return await this.client.records.update(record, id, payload);
    } catch (err) {
      throw err as errorresponse;
    }
  }

  public getlist(
    recordname: string,
    page: number,
    perpage: number,
    filter: any
  ): Observable<any> {
    try {
      return from(
        this.client.records.getList(recordname, page, perpage, filter)
      );
    } catch (err) {
      throw err as errorresponse;
    }
  }

  public updateObservable(
    record: string,
    id: any,
    payload: any
  ): Observable<any> {
    try {
      this.loaderservice.setloader(true);
      return new Observable((observer) => {
        this.client.records
          .update(record, id, payload)
          .then((record) => {
            observer.next(record);
            observer.complete();
          })
          .catch((err: any) => observer.error(err))
          .finally(() => this.loaderservice.setloader(false));
      });
    } catch (err) {
      throw err as errorresponse;
    }
  }
  public createObservable(record: string, payload: any): Observable<any> {
    try {
      this.loaderservice.setloader(true);
      return new Observable((observer) => {
        this.client.records
          .create(record, payload)
          .then((record) => {
            observer.next(record);
            observer.complete();
          })
          .catch((err: any) => {
            observer.error(err);
          })
          .finally(() => this.loaderservice.setloader(false));
      });
    } catch (err) {
      this.loaderservice.setloader(false);

      throw err;
    }
  }
  public getlistObservable(
    recordname: string,
    page: number,
    perpage: number,
    filter: any
  ): Observable<any> {
    try {
      this.loaderservice.setloader(true);
      return new Observable((observer) => {
        this.client.records
          .getList(recordname, page, perpage, filter)
          .then((record) => {
            observer.next(record);
            observer.complete();
          })
          .catch((err) => observer.error(err))
          .finally(() => this.loaderservice.setloader(false));
      });
    } catch (err) {
      throw err as errorresponse;
    }
  }

  public getlistsync(
    recordname: string,
    page: number,
    perpage: number,
    filter: any
  ): any {
    this.client.records
      .getList(recordname, page, perpage, filter)
      .then((rec) => {
        var items = rec.items;
        return items.length;
      })
      .catch((err) => {
        throw err as errorresponse;
      });
  }
}

export interface IDatabaseService {
  getone(record: string, id: any): Observable<any>;
  getlist(
    recordname: string,
    page: number,
    perpage: number,
    filter: any
  ): Observable<any>;
  update(record: string, id: any, payload: string): void;
  create(record: string, payload: string): void;

  getfile(record: string, filename: string, params: any): Observable<any>;
}
export interface response {
  items: any[];
  totalpages: number;
}
export interface errorresponse {
  code: number;
  message: string;
  data: any;
}
