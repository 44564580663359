import { Injectable } from '@angular/core';
import { Callback } from 'rollbar';
import { BehaviorSubject, catchError, EMPTY, flatMap, map, Observable, tap } from 'rxjs';
import { Ipager } from 'src/app/common/components/apptable/apptablepagination.component';
import { NotifiyService, RequestHandlerResponse } from 'src/app/common/components/notifications/notifiy.service';
import { DatabaseService } from 'src/app/common/services/database.services';
import { Iteacher } from '../interface/iteacher';

@Injectable({
  providedIn: "root",
})
export class TeacherService {
  private teacherSubject = new BehaviorSubject<Iteacher[]>([] as Iteacher[]);
  public teacher$ = this.teacherSubject.asObservable();
  private pagerSubject = new BehaviorSubject<Ipager>({
    page: 0,
    perpage: 0,
    totalItems: 0,
    totalPages: 0,
  });

  pagerObserverable$ = this.pagerSubject.asObservable();
  private record = "teachers";

  constructor(private dataservice: DatabaseService,
    private notifyService: NotifiyService) {}

  public create(teacher: Iteacher) {
   return this.dataservice.createObservable(this.record, JSON.stringify(teacher))
   .pipe(catchError(this.logNotification))
  }
  private logNotification(err:any):Observable<any>
  {
    console.log(err.data.code)
    if(err.data.code ===400)
    {
      console.log(err.data)
      this.notifyService.createNotification({success: false,message: "Validation error, please ensure Id & email are unique"} as RequestHandlerResponse)
    } 
    return EMPTY
  }
  public update(teacher: Iteacher) {
    return this.dataservice.updateObservable(this.record, 
      teacher.id, JSON.stringify(teacher))
  }
  public getlist(): Observable<any> {
    return this.dataservice.getlist(this.record, 1, 100, {}).pipe(
      map((r) => {
        return r.items;
      })
    );
  }
  public getlistObservable(
    page: number = 1,
    perpage: number = 100
  ): Observable<Iteacher[]> {
    return this.getteachers(page, perpage, {}).pipe(
      tap((response) => {
        const { page, perPage, totalItems } = response as any;
        const totalpages = Math.ceil(totalItems / perPage);
        this.pagerSubject.next({
          page: page,
          perpage: perPage,
          totalItems: totalItems,
          totalPages: totalpages,
        });
      }),
      map((teacherResponse) => this.toteacherobject(teacherResponse)),
      tap((t) => this.teacherSubject.next(t))
    );
  }

  private getteachers(
    page: number,
    perpage: number,
    filter: any
  ): Observable<Iteacher[]> {
    return this.dataservice.getlistObservable(
      this.record,
      page,
      perpage,
      filter
    );
  }

  private toteacherobject(item: any): Iteacher[] {
    var teachers = item.items.map((teacher: any) => {
      return {
        id: teacher.id,
        name: teacher.name,
        surname: teacher.surname,
        email: teacher.email,
        gender: teacher.gender,
        iDNumber: teacher.iDNumber,
        mobileTelephone: teacher.mobileTelephone,
        address: teacher.address,
        postalCode: teacher.postalCode,
        startdate: teacher.startdate,
      } as Iteacher;
    });
    return teachers;
  }
  public get(teacherid: any): Observable<Iteacher> {
    return this.dataservice.getone(this.record, teacherid);
  }
}
