<section class=" padding-top-2em" *ngIf="expense?.id !== undefined">
   <h4> Created By {{expense?.userprofiename}}</h4>
   <span style="font-family:monospace">Created Date {{expense?.created}}</span>
</section>

<form [formGroup]="expenseForm"
 (ngSubmit)="onSubmit()" >
<div class="form-control grid col-2 padding-top-2em " style="min-width: 40vw;">
  <div class="form-input ">
    
      <label for="name">Description</label>
      <input placeholder="Enter Item"  
             type="text" 
             name="name" 
             id="name" 
             formControlName="name"/>
      <span *ngIf="expenseForm.get('name')?.dirty  && expenseForm.get('name')?.errors">
          Invalid Name!
      </span>
  </div>
  <div class="form-input">
    <label for="quantity" >Quantity</label>
    <input placeholder="Enter Quantity" 
           type="number" 
           name="quantity" 
           id="quantity" 
           formControlName="quantity"/>
    <span *ngIf="expenseForm.get('quantity')?.dirty &&  expenseForm.get('quantity')?.errors">
        Invalid reason!
    </span>
</div>
<div class="form-input">
  <label for="cost">Total Cost</label>
  <input placeholder="Enter cost" 
         type="text" 
         name="cost" 
         id="cost" 
         formControlName="cost"/>
  <span *ngIf="
          expenseForm.get('cost')?.dirty &&
          expenseForm.get('cost')?.errors">
      Invalid cost!
  </span>
</div>
<div></div>
  <div class="form-input">
      <label for="reason" >Reason</label>
      <input placeholder="Enter Reason" 
             type="text" 
             name="reason" 
             id="reason" 
             formControlName="reason"/>
      <span *ngIf="expenseForm.get('reason')?.dirty &&  expenseForm.get('reason')?.errors">
          Invalid reason!
      </span>
  </div>
  <div class="form-input">
      <label for="category" >
        Category
      </label>
      <input placeholder="Enter category" 
             type="text" 
             name="category" 
             id="category" 
             formControlName="category"/>
      <span *ngIf="expenseForm.get('category')?.dirty &&
                   expenseForm.get('category')?.errors">
          Invalid category!
      </span>
  </div>

 
  <div class="form-input">
    <label for="attachment" >
      File Attachment</label>
    <input placeholder="Upload Attachment"
           type="file" 
           name="attachment" 
           id="attachment" 
           (change)="onChangeFile($event)"
           
           formControlName="attachment"/>
    <span *ngIf="
          expenseForm.get('attachment')?.dirty &&
          expenseForm.get('attachment')?.errors">
        Invalid attachment!
    </span>
</div>
<section></section>
</div>
<section class="model-action">
  <span class="error-text" *ngIf="fileSizeValidation.length >0">
    {{fileSizeValidation}}
  </span>
  <div class="form-control grid col-4 button-md">
    <div></div>
    <button 
      type="button"
      class="greybtn"
      style="margin-right: 2.5em;"
      (click)="onclose()">
      Close
    </button>
    <button *ngIf="!loader else savedata" 
    type="submit"  
    [disabled]="!expenseForm.valid"
     style="padding: 0.5em 2em;" >submit</button>
    <ng-template #savedata>
       <button  type="button" [disabled]="true" style="padding: 0.5em 2em;">Saving...</button>
    </ng-template>
  </div>
</section>
</form>

<section class=" padding-top-2em" *ngIf="expense?.id !== undefined">
 
</section>
