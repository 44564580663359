import { Action, createFeatureSelector, createReducer, createSelector, on } from "@ngrx/store";
import { IGrade } from "../igrade.model";
import { loadGradeFail, loadGradeSuceess } from "./grade.actions";

export interface GradeState {
  grades: IGrade[];
  err: any | undefined;
}

const initialState: GradeState = {
  grades: [],
  err: undefined,
};

const _gradeReducers = createReducer(
  initialState,
  on(loadGradeSuceess, (state, gradeContainer) => ({
    ...state,
    grades: gradeContainer.grades,
  })),
  on(loadGradeFail, (state, errContainer) => ({
    ...state,
    err: errContainer.err,
  }))
);

export function GradeReducers(state: any| undefined, action: Action)
{
    return _gradeReducers(state,action)
}


export const  getGradeState= createFeatureSelector<GradeState>("grades")

export const getGrades = createSelector(
getGradeState,
(state:GradeState) => state.grades
)