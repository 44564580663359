import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { map, Observable, of, take, tap } from 'rxjs';
import { AuthService } from 'src/app/pages/auth/service/auth.service';
import { UserService } from 'src/app/pages/auth/service/user.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  isloggedIn$ = this.authService.isloggedIn$;
  
  authUser$ =this.authService.user$

  constructor(private authService: AuthService
    ,  private router: Router) {
  
   }
  
  ngOnInit(): void {

  }
  logout(){

    this.authService.logout();
    this.router.navigate(['/login']);
  }
}
