import { createAction, props } from "@ngrx/store";
import { Istudent } from "../services/student.service";

export const loadStudents = createAction("[Student Component] LoadStudents");
export const loadStudentsFail = createAction(
  "[Student Component] LoadStudents",
  props<{ err: any }>()
);
export const loadStudentSuccess = createAction(
  "[Student Component] LoadStudentsSuccess",
  props<{ students: Istudent[] }>()
);
export const SelectStudent = createAction(
  "[Student Component] SelectStudent",
  props<{ studentId: string }>()
);
export const SaveStudent = createAction(
  "[Student Component] SaveStudents",
  props<{ student: Istudent }>()
);
export const SaveStudentSuccess = createAction(
  "[Student Component] SaveStudentsSuccess",
  props<{ student: Istudent }>()
);
export const SaveStudentFailed = createAction(
  "[Student Component] SaveStudentsFailed",
  props<{ err: any }>()
);
