import { Injectable, OnInit } from "@angular/core";

import { BehaviorSubject, map, Observable, of, Subject } from "rxjs";
import { LoaderService } from "../../common/services/loader.service";
import { NotifiyService } from "../../common/components/notifications/notifiy.service";
import { DatabaseService } from "src/app/common/services/database.services";
import { IGrade, toGradeArray } from "./igrade.model";
import { select, Store } from "@ngrx/store";
import { getGrades } from "./store/grade.reducers";
@Injectable({
  providedIn: "root",
})
export class GradeService implements OnInit {
  private gradecollection = "class";
  private client = this.databaseservice.getclient();

  private gradesSubject = new BehaviorSubject<IGrade[]>([]);
  public grade$ = this.gradesSubject.asObservable();
  public gradeStatic!: Array<IGrade>;

  private selectedgradeSubject = new Subject<IGrade>();
  public selectedgrade$ = this.selectedgradeSubject.asObservable();
  public staticgrade: IGrade | undefined;

  constructor(
    private notifyService: NotifiyService,
    private loaderService: LoaderService,
    private databaseservice: DatabaseService,
    private store: Store
  ) {}
  ngOnInit(): void {}

  GetGrades() {
    this.client.records
      .getList("class")
      .then((c) => {
        this.gradeStatic = new Array<IGrade>();

        //c.items as unknown as IGrade[]
        c.items.forEach((grade_: any) => {
          var grade = {
            Id: grade_.id,
            teacher: grade_.teacher,
            name: grade_.name,
            fees: grade_.fees,
            classBadge: grade_.classBadge,
            registration: grade_.registration,
            teacherid: grade_.teacherid,
          } as IGrade;
          this.gradeStatic.push(grade);
        });

        console.log(this.gradeStatic);
        this.gradesSubject.next(this.gradeStatic);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  GetGradeList(): Observable<IGrade[]> {
    return this.databaseservice
      .getlistObservable(this.gradecollection, 1, 20, {})
      .pipe(map((c) => toGradeArray(c)));
  }
  getGradeObservable(_grade:string):Observable<IGrade>
  {
    return this.store.pipe(select(getGrades))
              .pipe(map(g=> g.filter(grade=> grade.name.toLocaleLowerCase() === _grade.toLocaleLowerCase())[0]))
    //return this.databaseservice.getlistObservable(this.)
  }
  filtergrade(gradename: string) {
    if (this.staticgrade == undefined) {
      this.GetGrades();
    }
    if (gradename === undefined) return;
    this.staticgrade = this.gradeStatic?.filter(
      (a) => a.name.toLocaleLowerCase() === gradename.toLocaleLowerCase()
    )[0];

    this.selectedgradeSubject.next(this.staticgrade);
  }

  getgradeByteacher(teacherid: any): Observable<IGrade> {
    if (teacherid !== undefined)
      return this.databaseservice.getlistObservable("class", 1, 2, {
        filter: 'teacher="' + teacherid + '"',
      });
    return of({} as IGrade);
  }
  getgradeByteacherSync(teacherid: any): IGrade {
    return this.databaseservice.getlistsync("class", 1, 2, {
      filter: 'teacher="' + teacherid + '"',
    })[0];
  }

  GetGrade(grade: string) {
    this.client.records
      .getOne("class", grade)
      .then((c) => {
        this.staticgrade = c as unknown as IGrade;
        this.selectedgradeSubject.next(this.staticgrade);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  CreateGrade(grade: IGrade) {
    if (grade.classBadge === undefined || grade.classBadge === "") {
      this.notifyService.createNotification({
        success: false,
        message: `Class badge cannot be empty, please select bagde to proceed`,
      });
      return;
    }

    this.loaderService.setloader(true);
    this.client.records
      .create("class", JSON.stringify(grade))
      .then((res) => {
        console.log(res);
        this.notifyService.createNotification({
          success: true,
          message: `Teacher: ${grade.teacher} Assigned to ${grade.name} `,
        });
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loaderService.setloader(false);
      });
  }
}

