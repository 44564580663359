import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import { debounce, debounceTime, map, Observable, switchMap, tap } from "rxjs";
import * as fromPaymentReducer from "./stores/payment.reducers";
import * as fromPaymentActions from "./stores/payment.actions";
import { Istudent } from "../students/services/student.service";
import { IGrade } from "../grades/igrade.model";
import { StudentState } from "../students/store/student.reducers";
import * as StudentActions from "../students/store/student.actions";
import { feemanager } from "../students/classes/Ifeepaymentmanager";
import { PaymentResponse, PaymentService } from "../students/services/payment.service";
@Component({
  selector: "app-payments",
  templateUrl: "./payments.component.html",
  styleUrls: ["./payments.component.scss"],
})
export class PaymentsComponent implements OnInit {
  constructor(
    private activeroute: ActivatedRoute,
    private store: Store<fromPaymentReducer.PaymentState>,
    private studentstore: Store<StudentState>,
    private paymentService: PaymentService
  ) {}
  studentPaymentDetails$!: Observable<{
    paymentstate: fromPaymentReducer.PaymentState;
    selectedStudent: Istudent;
    selectGrade: IGrade;
    yearlyTotal: number;
    arrears: number;
    studentstartMonth: number;
  }>;

  routeActive$ = new Observable<any>();
  ngOnInit(): void {
    this.studentstore.dispatch(StudentActions.loadStudents());
    this.routeActive$ = this.activeroute.params.pipe(
      map(({ studentid }) => studentid),
      tap((studentid) =>
        this.store.dispatch(
          fromPaymentActions.loadPaymentHistory({ studentId: studentid })
        )
      ),
      debounceTime(1000),
      switchMap(async (studentid) =>
        (this.studentPaymentDetails$ = this.store.select(
          fromPaymentReducer.getStudentPaymentDetails
        )).pipe(tap(console.log))
      )
    );
  }
  async onSubmit(
    
    fee: number,
    studentPayment: any[],
    student: Istudent,
    grade: IGrade,
    startmonth: number
  ) {

    console.log(studentPayment);
    const manager = new feemanager(
      studentPayment as PaymentResponse[],
      student,
      grade,
      fee ?? 0,
      this.paymentService,
      startmonth
    );
    await manager.processpayment();

    fromPaymentActions.loadPaymentHistory({ studentId: student.id });
  }
}
