<ng-container>
    <section *ngIf="showpop" class="overlay-modal">
        <div class="model-content">
            <a class="closebtn" [routerLink]="['/fee']" href="javascript:void(0)"
             routerLinkActive="router-link-active" >X</a>
            <section class="header">
                <h3 class="attention">
                        Payment required!
                </h3>
            </section>
            <section class="content">
                    <h3 class="invalid">
                        <span> Registration fee:</span> 
                        {{requiredFee | currency:"R"}}
                     </h3>
                    <p>Student Number: <span>{{student?.studentNumber}}</span></p>
                    <p>{{student?.grade}}</p>
                    <p>Student Name:
                        <span>
                            {{student?.name}} {{student?.surname}}
                        </span>
                    </p>
            </section>
            <section class="action md-controls">
                <app-paymentform 
                [maxvalue]="requiredFee"
                (paymentevent)="submit($event)"
                [defaultvalue]=requiredFee></app-paymentform>
            </section>
        </div>
    </section>
</ng-container>

