import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { filter, map, Observable } from 'rxjs';
import { AuthService,  } from 'src/app/pages/auth/service/auth.service';
import { NotifiyService, RequestHandlerResponse } from '../notifiy.service';

@Component({
  selector: 'app-error-notify',
  templateUrl: './error-notify.component.html',
  styleUrls: ['./error-notify.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorNotifyComponent implements OnInit {
  @Input() issuccess:boolean =true;
  requestresponse$ =
  this.notifyService?.requestHandler$

  constructor(private notifyService: NotifiyService) { 

  }

  ngOnInit(): void {
    
  }

}
