<div class="container minheight-70vh grid col-2-auto justify-items-end">
    <ng-container *ngIf="studentPaymentDetails$| async as details;else generatereport">
        <section>
            <h3 class="margin-1em-sides textalign-center margin-top-4em" *ngIf="details.selectedStudent as student">
                <p>
                    {{student.studentNumber}}_{{student.name }}
                    {{student.surname}}</p>
                <span>({{student.grade}})</span>
            </h3>
            <app-payment-grid [studentpayments]="details.paymentstate.ledger" [gradeFee]="details.selectGrade.fees" [startMonth]="details.studentstartMonth"></app-payment-grid>
        <section class="form-control grid col-2 padding-2em  form_fix_bottom">
            <app-paymentform 
            (paymentevent)="onSubmit($event,details.paymentstate.ledger,details.selectedStudent,details.selectGrade,details.studentstartMonth)"
             [maxvalue]="details.yearlyTotal - (details.paymentstate.totalPaid- details.selectGrade.registration) "></app-paymentform>
        </section>
        </section>
        <section style="margin: 3em 1em;">
            <app-table [data]="[details.selectedStudent.startdate]" css="min-width-280px margin-top-bottom-1em ">
                <ng-template #headers>
                    <th style="text-align: center;">Student Information</th>
                </ng-template>
                <ng-template #rows let-row>
                    <tr>
                        <td>Start Date:
                        </td>
                        <td>{{row |date : 'dd MMMM YYYY'}}</td>

                    </tr>
                </ng-template>
            </app-table>
            <app-table [data]="[1]" css="min-width-280px margin-top-bottom-1em ">
                <ng-template #headers>
                    <th style="text-align: center;">Summary</th>
                </ng-template>
                <ng-template #rows let-row>
                    <tr>
                        <td>Amount Paid:</td>
                        <td>
                            {{details.paymentstate.totalPaid- details.selectGrade.registration| currency:"R"}}</td>

                    </tr>
                    <tr>
                        <td>Registration Paid:</td>
                        <td>
                            {{(details.paymentstate.registrationPaid?details.selectGrade.registration:0) | currency:"R"}}</td>
                    </tr>
                    <tr>
                        <td>Yearly Fee:</td>
                        <td>
                            {{details.yearlyTotal | currency:"R"}}</td>
                    </tr>
                    <tr>
                        <td>{{details.arrears<0? "Arrears":"Credit"}}</td>
                        <td>{{details.arrears | currency:"R"}}</td>
                    </tr>
                    <tr>
                        <td>Outstanding balance:</td>
                        <td>{{details.yearlyTotal - (details.paymentstate.totalPaid- details.selectGrade.registration) | currency:"R"}}</td>
                    </tr>
                </ng-template>
            </app-table>
        </section>
        <app-registrationfeeblockscreen 
        [student]="details.selectedStudent" 
        [showpop]="!details.paymentstate.registrationPaid" 
        [requiredFee]="details.selectGrade.registration"
        
        ></app-registrationfeeblockscreen>

    </ng-container>
    <ng-template #generatereport>
        Generating report...
    </ng-template>
</div>

<ng-container *ngIf="routeActive$ | async"></ng-container>

