<div class="container minheight-70vh padding-4em">
    <form [formGroup]="gradeform" (ngSubmit)="onSubmit()"novalidate="novalidate">
        <div *ngIf="formstep==1"  class="form-max-control-70 margin-top-bottom-1em" >
        <div class="title-bar grid col-2">
            Class Infomation
            <div>
                <img *ngIf="this.gradeform.valid" src="../../../assets/images/checkbox.svg"></div>
            </div>

            <div class="form-control grid col-4">
                <div class="form-input">
                    <input placeholder="Select Teacher" list="teacher-list" name="teacher" formControlName="teacher"/>
                    <datalist id="grade-list">
                        <option value="Monkey Class"></option>
                    </datalist>
                    <span *ngIf="!this.gradeform.pristine
                            && !this.gradeform.get('teacher')?.valid">
                        Invalid Teacher!
                    </span>
                </div>
            </div>
            <div class="form-control grid col-3">
                <div class="form-input">
                    <input placeholder="Grade Name" type="text" name="class" formControlName="name"/>
                    <span *ngIf="!this.gradeform.pristine
                                            && !this.gradeform.get('name')?.valid">
                        Invalid Grade!
                    </span>
                </div>
                <div class="form-input">
                    <input placeholder="Fees" type="text" name="fees" formControlName="fees"/>
                    <span *ngIf="!this.gradeform.pristine
                                            && !this.gradeform.get('fees')?.valid">
                        Invalid Fees!
                    </span>
                </div>
            </div>
            <div class="form-control grid col-4 button-md">
                <span></span>
                <button *ngIf="this.gradeform.valid;else invalidform" type="submit" class="greenbtn">
                    Submit
                </button>
                <ng-template #invalidform>
                    <button type="button" disabled="true">
                        Submit
                    </button>
                </ng-template>
            </div>
        </div>
            <div *ngIf="formstep==2">
                <div class="form-control padding-2em">
                    <span *ngIf="this.gradeform.get('classBadge')?.value === ''; else selectedbadge">Select Class badge</span>
                    <ng-template #selectedbadge ngOutletContext]="context">
                        <span >{{this.gradeform.get('classBadge')?.value | uppercase}}</span>
                    </ng-template>
                    <div class="grid col-6 gap-2px width-500px">
                        <a *ngFor="let grade of grades" [ngClass]="grade?.icon">
                            <app-icons [icon]="grade?.icon" class_css="icon" (selectIcon)="selectbadge($event)"></app-icons>
                        </a>
                    </div>
                </div>
                <div class="form-control grid col-4 button-md padding-2em">
                    <span></span>
                    <button *ngIf="this.gradeform.get('classBadge')?.value !== '' ;else invalidform" type="submit" class="greenbtn">
                        Submit
                    </button>
                    <ng-template #invalidform>
                        <button type="button" disabled="true">
                            Submit
                        </button>
                    </ng-template>
                </div>
            </div>
    </form>
  
        <app-error-notify></app-error-notify>
    </div>

