import { Injectable, OnDestroy } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable, take, takeUntil } from "rxjs";
import { AuthService } from "../pages/auth/service/auth.service";

@Injectable({
  providedIn: "root",
})
export class AuthGaurd implements CanActivate,OnDestroy {
  constructor(private authService: AuthService
    ,private router: Router) {}
  ngOnDestroy(): void {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | boolean
     {

        if(!this.authService.getAuthStatus())
        {
          this.router.navigate(['/login']);
        }
        return true
     
   
  }
}
