import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map } from "rxjs";
import { GradeService } from "../grade.service";
import * as fromGradactions from "./grade.actions";

@Injectable()
export class GradesEffects {
  constructor(private gradeService: GradeService, private action$: Actions) {}

  loadGrades = createEffect(() => {
    return this.action$.pipe(
      ofType(fromGradactions.loadGrade),
      concatMap(() =>
        this.gradeService.GetGradeList().pipe(
          map((grades) => fromGradactions.loadGradeSuceess({ grades })),
          catchError(async (err) => fromGradactions.loadGradeFail(err))
        )
      )
    );
  });
}
