import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  ValidatorFn,
  ValidationErrors,
  AbstractControl,
} from "@angular/forms";
import { Iexpense } from "../../interfaces/Iexpense";

@Component({
  selector: "app-expense-edit",
  templateUrl: "./expense-edit.component.html",
  styleUrls: ["./expense-edit.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseEditComponent implements OnInit {
  public expenseForm = new FormGroup({
    id: new FormControl(""),
    name: new FormControl("", Validators.required),
    quantity: new FormControl("", [Validators.required, Validators.min(0)]),
    reason: new FormControl("", Validators.required),
    attachment: new FormControl("", [FileSizeValidator(50)]),
    category: new FormControl("", Validators.required),
    cost: new FormControl("", Validators.required),
  });
  @Output() cancelform = new EventEmitter();
  @Output() onSubmitform = new EventEmitter<Iexpense>();
  @Input() expense: Iexpense | undefined | null;
  @Input() loader: boolean
  file: File | undefined;

  fileSizeValidation = "";

  private fileSize = 5242880;
  private sizeconversion = 1000000;
  constructor() {
    this.loader =false
  }

  ngOnInit() {
    if (this.expense?.id !== undefined) {
      this.expenseForm.patchValue({
        id: this.expense?.id,
        quantity: this.expense.quantity.toString(),
        name: this.expense?.name,
        cost: this.expense?.cost.toString(),
        category: this.expense?.category,
        reason: this.expense?.reason,
      });
    }
  }

  onChangeFile(event: any) {
    try {
      this.fileSizeValidation = "";
      this.file = event.target.files[0];
      if (this.file?.size !== undefined && this.file.size > this.fileSize) {
        const filesize_mb = this.file.size / this.sizeconversion;
        const actualFilesize_mb = this.fileSize / this.sizeconversion;
        this.fileSizeValidation = `File exeeds ${filesize_mb.toFixed(
          2
        )}mb, actual file size is ${actualFilesize_mb.toFixed(2)}mb`;
      }
    } catch (err) {
      this.fileSizeValidation = "An Error occured uploading file!";
    }
  }
  onclose() {
    this.cancelform.emit();
  }
  onSubmit() {
    var expense = this.expenseForm.getRawValue() as unknown as Iexpense;
     expense.attachment = this.file;
    this.onSubmitform.emit(expense);
  }
}
export function FileSizeValidator(filesize: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
  
    return null;
  };
}
