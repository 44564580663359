import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: "app-icons",
  template: `
    <div (click)="selectbadge(icon,title)">
      <div  [ngClass]="[icon,class_css,
        !animate?'' :'smooth-animate']"></div>
      <span *ngIf="title !==undefined" class="icon-lg-title"> {{ title }}  </span>
      </div>
    <!--       
      <div class="bear icon"></div>
      <div class="bag icon"></div>
      <div class="jellyfish icon"></div>
      <div class="bells icon"></div> -->
  `,
  styleUrls: ["./icons.component.css"],
})
export class IconsComponent implements OnInit {
  @Output() selectIcon = new EventEmitter<{icon:string,title:string}>();
  @Input() icon:string | undefined
  @Input() title:string | undefined
  @Input() class_css = "icon";
  @Input() animate=false;

  constructor() {}

  selectbadge (icon:any,title:any)
  {
    this.selectIcon.emit({icon,title})
  }


  ngOnInit() {

  }
}
