import { NgModule } from "@angular/core";
import { PaymentsComponent } from "./payments.component";
import { PaymentNewGridComponent } from "./components/payment-grid.component";
import { PaymentEffects } from "./stores/payment.effects";
import { EffectsModule } from "@ngrx/effects";
import { SharedModule } from "src/app/common/shared.module";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { PaymentLedgerService } from "./services/payment.ledger.service";
import { StudentModuleModule } from "../students/student-module/student-module.module";

@NgModule({
    declarations: [PaymentsComponent, PaymentNewGridComponent],
    exports: [PaymentsComponent],
    providers: [PaymentLedgerService],
    imports: [
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        SharedModule,
        StudentModuleModule,
        EffectsModule.forFeature([PaymentEffects]),
        StudentModuleModule
    ]
})
export class PaymentModule {}
