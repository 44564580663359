import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-student-edit-loader',
  template: `<form>
  <section class="grid col-2">
      <div class="grid profile-section" style="justify-items: flex-start;;justify-self: baseline">
          <span class="student-title-ms student-loading borderradius">Illness: N/A</span>
          <span class="student-title-ms student-loading borderradius">Medical Group: N/A</span>
          <span class="student-title-ms student-loading borderradius">Doctor: N/A</span>
          <span class="student-title-ms student-loading borderradius"></span>
          <span class="student-title-ms student-loading borderradius">Next of Kin: Alpha</span>
          <span class="student-title-ms student-loading borderradius">Next of Kin Contact: N/A</span>
      </div>
      <section class="profile-section grid">
          <img class="round-image student-loading">
              <span class="student-title student-loading borderradius">
                  Warona Hlahla</span>
              <span class="student-title-ms student-loading borderradius">Student number: HK-BB-0-WH</span>
              <span class="student-title-ms student-loading borderradius">Bunny Bears</span>
          </section>
      </section>
      <section >
          <h3>Edit form</h3>
      <div class="form-control grid col-3">
          <div class="form-input ">
              <span class="student-loading textinput borderradius"></span>
          </div>
          <div class="form-input">
              <span class="student-loading textinput borderradius"></span>
          </div>
          <div class="form-input">
              <span class="student-loading textinput borderradius"></span>
          </div>
          <div class="form-input">
              <span class="student-loading textinput borderradius"></span>
          </div>
          <div class="form-input">
              <span class="student-loading textinput borderradius"></span>
          </div>

          <div></div>
          <div class="form-input">
              <span class="student-loading textareainput borderradius"></span>
          </div>
          <section></section>
          <section></section>
          <div class="form-input">
              <span class="student-loading textinput borderradius"></span>
          </div>
      </div>     
  </section>
  </form>`,
})
export class StudentEditLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
