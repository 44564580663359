<h3 class="textalign-center margin-top-4em">Happy kids Teacher Master</h3>
<div class="container scrollpane-40vh padding-4em-sides margin-top-2em ">
 
    <ng-container *ngIf="teachers$ | async  as teachers;else noteacher">
        <div class=" grid col-3" >
            <div class="form-control fix-bottom-right z-index-front">
                <button  class="circlebtn" (click)="addteacher()"> 
                    <span> 
                </span>
                <img  src="/assets/images/plus.svg"/>
            </button>
          
            </div>
            
            <span></span> 
        </div>
        <div class=" grid">
            <app-table [data]="teachers" css="min-width-280px"
            [pager]="(pager$ | async) ?? {page:1,perpage:1 ,totalItems:0, totalPages:0}" 
            >
                <ng-template #headers>
                    <th class="textalign-center ">
                        <form style="width: 144px;" class="form-input">
                            <span>
                                Teacher Name
                            </span>
                        </form>
                    </th>
                   
                    <th class="textalign-center">
                        Email</th>
                    <th class="textalign-center">
                        Telephone</th>
                    <th class="textalign-center">
                        Action</th>

                </ng-template>
                <ng-template #rows let-row>
                    <td>{{row.name}}</td>
                    <!-- <td style="padding: 0;">{{row.id | getgradeFromTeacherPipe}}</td> -->
                    <td>{{row.email}}</td>
                    <td>{{row.mobileTelephone}}</td>
                    <td class="td-control grid form-control">
                        <button type="button" class="button grid col-2"
                        (click)="onSelectTeacher(row.id)" >
                                <span class=" grid">
                                    Edit
                            </span>
                            <img  src="/assets/images/pencil.svg"/>
                        </button>
                    </td>
                </ng-template>
            </app-table>
        </div>
    </ng-container>
    <ng-template #noteacher>
        <div class="grid place-item-center padding-2em form-control">
            <button (click)="addteacher()"> Add teacher</button>
        </div>
        
    </ng-template>
</div>

<app-sidemodalcontainer
             [title]="title"
             [show]="showmodel">
    <ng-template #content>
  <app-teacher-edit
  (canceled)="closeModel()"
  [teacher]="(selectedteacher$ | async ) ?? undefined"
  (submited)="onformSubmit($event)"
  >

  </app-teacher-edit>
</ng-template>
</app-sidemodalcontainer>
<ng-container *ngIf="selectedteacher$ |async"></ng-container>

