

    <!-- <app-expensefilter
    ></app-expensefilter> -->
    
  
    <ng-container *ngIf="expenselist">
        <div class=" grid col-3" >
        <span></span>
            <div class="form-control fix-bottom-right z-index-front">
                <button  class="circlebtn" (click)="AddExpense()"> 
                    <span> 
                </span>
                <img  src="/assets/images/plus.svg"/>
            </button>
          
            </div>
            <span></span> 
        </div>
        <div class="grid padding-2em-top">
            <app-table [data]="expenselist"
                        [pager]="(pager$ |async) ?? {page:1,perpage:1 ,totalItems:0, totalPages:0}"
                        (onpage)="onpaginate($event)"
            css="min-width-280px">
                <ng-template #headers>
                    <th class="textalign-center ">
                        <form style="width: 144px;" class="form-input">
                            <span>
                                Description
                            </span>
                        </form>
                    </th>
                    <th>
                        <span>
                            Quantity
                        </span>
                    </th>
                    <th class="textalign-center">
                        <span>
                            Total Cost
                        </span>
                    </th>
                    <th class="textalign-center">
                        Created date</th>
                    <th class="textalign-center">
                        Created By</th>
                    <th class="textalign-center">
                        Action</th>

                </ng-template>
                <ng-template #rows let-row>
                    <td>{{row.name}}</td>
                    <td>{{row.quantity}}</td>
                    <td style="padding: 0;">{{row.cost | currency :'R'}}</td>
                    <td>{{row.created | date : 'dd MMMM YYYY'}}</td>
                    <td>{{row.userprofiename}}</td>
                    <td class="td-control grid form-control">
                        <button type="button" class=" clearbutton grid col-2"
                            (click)="selectExpense(row)">
                                <span class=" grid">          
                            </span>
                            <img  src="/assets/images/pencil.svg"/>
                        </button>
                    </td>
                </ng-template>
            </app-table>
        </div>
    </ng-container>


