<ng-container *ngIf="requestresponse$ | async as notification">
    <ng-container *ngIf="notification.success !== undefined">
        <div class="notification-container error-container" *ngIf="!notification.success && notification.message !== '';else success">
            <span>
                {{notification.message}}
            </span>
        </div>
        <ng-template #success>
            <div class="notification-container success-container fixed-end" *ngIf="notification.success && notification.message !== ''">
                <span>
                    {{notification.message}}
                </span>
            </div>
        </ng-template>
    </ng-container>

</ng-container>

