import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoaderService } from '../../services/loader.service';

@Component({
  selector: 'app-goback',
  templateUrl: './goback.component.html',
  styleUrls: ['./goback.component.css']
})
export class GobackComponent implements OnInit {

  assetsPath = environment.assetspath
  constructor(private location: Location,
    public loaderService : LoaderService) { }

  ngOnInit() {
  }
  goBack(){
    this.location.back();
  }
}
