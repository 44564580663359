<section class="container minheight-70vh">
<ng-container *ngIf="studentsState$ |async as studentsstate">
    <div class="padding-4em">
        <div class=" grid col-3">
            <div class="payhere-container">
                <a *ngIf="studentsstate.selected as vmstudents" [routerLink]="['/payment',vmstudents?.id]">
                    <span class="paybtn-image">Pay</span>
                    <h3>Pay here</h3>
                </a>
            </div>
            <h2>Fees</h2>
            <section>
            <app-studentsearch [students]="studentsstate.students" (studentFilter)="filterStudent($event)"></app-studentsearch>
            <div class="grid place-content-baseline" *ngIf="studentsstate.selectedStudent">
                    <h2>{{studentsstate.selected?.name}}

                    {{studentsstate.selected?.surname}}

                                                </h2>
                                                <span>
                    {{studentsstate.selected?.grade}}
                            </span>
                        </div>
                        <ng-container [ngTemplateOutlet]="PaymentDetails">

                        </ng-container>
            </section>


        </div>
    </div>
</ng-container>

<ng-template #PaymentDetails>
    <div *ngIf="studentPaymentDetails$  | async as details">
        <div class="Student Status ">
<div class="studentStatus grid place-content-baseline">

                <div class="grid col-3 place-item-center">
            <ng-container *ngIf="paymentdetailsState$ |async as paymentDetails">
                    <ng-container *ngIf="paymentDetails.loading">
                        <app-loader-inline></app-loader-inline>
                    </ng-container>
                    <ng-container *ngIf="!paymentDetails.loading && paymentDetails.totalPaid >-1;">
                        <h3>Status</h3>
                        <ng-template #goodStatus>
                              <img width="70" src="/assets/images/check.png" alt="" status="Status" image="image"/>
                        </ng-template>

                        <ng-container *ngIf="paymentDetails.totalPaid <  1; else goodStatus">
                            <img width="70" src="/assets/images/fail.png" alt="" status="Status" image="image"/>
                        </ng-container>
                    </ng-container>

                    </ng-container>
                </div>
            </div>
            <div class="grid place-item-center greyimagefilter">
                <img style="width:10em;" src="/assets/images/calendar.svg" alt="calendar">
                    <div>
                        <img style="width: 50px;padding: .8rem;" src="/assets/images/print.svg" alt="">
                            <img style="width: 50px;padding: .8rem;" src="/assets/images/download.svg" alt="download"></div>
                        </div>
                    </div>
                </div>
            </ng-template>
</section>

