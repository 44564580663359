import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "pipeselection",
})
export class PipeselectionPipe implements PipeTransform {
  transform(value: string, arrears?: any): any {
    if (value.indexOf("|") > 0) {
      return value.split("|")[arrears ? 0 : 1];
    }
    return value.replace("_"," ");
  }
}
@Pipe({
  name: "initialsPipe",
})
export class InitialsPipe implements PipeTransform 
{
  transform(value: string, ...args: any[]) {
    var initials = new Array<string>()
    var values = value.trim().split(' ');

    for(var i = 0;i < values.length; i++)
      initials.push(values[i][0])

    return initials.join(',').replace(",","")

  }
}
