import { ErrorHandler, Injectable } from "@angular/core";
import { NotifiyService } from "../components/notifications/notifiy.service";

@Injectable({
  providedIn: "root",
})
export class RollbarErrorHandler implements ErrorHandler {
  constructor(private notifyService: NotifiyService) {}
  handleError(err: any): void {
    var messageresponse = "";

    for (const [key, value] of Object.entries(err.data?.["data"])) {
      var errorcontext = value as { code: string; message: string };
      messageresponse = `${messageresponse} \n ${key}: ${errorcontext.message}`;
    }
    
    console.log(messageresponse);
    this.notifyService.createNotification({
      success: false,
      message: messageresponse,
    });
  }
}

const rollbarConfig = {
  accessToken: "652fe498a07c40f094aba9232a24854a",
  captureUncaught: true,
  captureUnhandledRejections: true,
};
