import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/common/shared.module';
import { TeacherEditComponent } from './components/teacher-edit/teacher-edit.component';
import { TeachergridComponent } from './components/teachergrid/teachergrid.component';
import { TeacherComponent } from './teacher.component';
import { ReactiveFormsModule } from '@angular/forms';
import { getgradeFromTeacherpipe } from './getteacherpipe.pipe';
import { GradesModule } from '../grades/grade.module';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    GradesModule
  ],
  declarations: [	
    TeacherComponent,
    TeacherEditComponent,
    TeachergridComponent,
    getgradeFromTeacherpipe
   ]
})
export class TeacherModule { }
