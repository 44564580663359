import { Pipe, PipeTransform } from '@angular/core';
import { GradeService } from '../grades/grade.service';

@Pipe({
  name: 'getgradeFromTeacherPipe'
})
export class getgradeFromTeacherpipe implements PipeTransform {
  constructor(private gradeservice :GradeService)
  {}
  transform(value: any, args?: any): any {
    if(value === null || value === undefined)
      return "N/A"
      try{
          return  this.gradeservice.gradeStatic.filter(a=>a.teacherid === value)[0].name;
      }catch
      {
        return "N/A"
      }
  }

}
