import { Injectable } from "@angular/core";
import { map, Observable, tap } from "rxjs";
import { NotifiyService } from "src/app/common/components/notifications/notifiy.service";
import { DatabaseService } from "src/app/common/services/database.services";
import { objectToParmentArray, Payment, PaymentLedger } from "../Payments.model";


@Injectable()
export class PaymentLedgerService {
  constructor(
    private databaseservice: DatabaseService,
    private notifyService: NotifiyService
  ) {}

  public getpaymentsObserverable(studentid: string): Observable<Payment[]> {
    return this.databaseservice
      .getlistObservable("payments", 1, 13, {
        filter: 'studentid.id="' + studentid + '"',
      })
      .pipe(
        tap(console.log),
        map((payments: any) => objectToParmentArray(payments))
      );
  }

  getPaymentHistory(payments: Payment[]): any[] {
    const payments_history = payments.filter((a) => a.type !== "r_fees");

    return months.map((month) => {
      if (payments_history.length > 0) {
        return {
          ...payments_history?.filter((a) => a.month === month.index)[0],
          month,
        };
      }
      return { ...[], month };
    });
  }

  feepaymentObservable(payment: Payment): Observable<any> {
    return this.databaseservice
      .createObservable("payments", JSON.stringify(payment))
      .pipe(
        tap(() => {
          if (payment.month > -1) {
            this.notifyService.createNotification({
              success: true,
              message: `Payment of R${payment.amount} added for the month of ${
                months[payment.month - 1].name
              }`,
            });
          }
        })
      );
  }
}


  export const months = [
    {
      index: 1,
      name: "January",
    },
    {
      index: 2,
      name: "February",
    },
    {
      index: 3,
      name: "March",
    },
    {
      index: 4,
      name: "April",
    },
    {
      index: 5,
      name: "May",
    },
    {
      index: 6,
      name: "June",
    },
    {
      index: 7,
      name: "July",
    },
    {
      index: 8,
      name: "August",
    },
    {
      index: 9,
      name: "September",
    },
    {
      index: 10,
      name: "October",
    },
    {
      index: 11,
      name: "November",
    },
    {
      index: 12,
      name: "December",
    },
  ];
