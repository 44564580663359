import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { tap } from "rxjs";
import { NotifiyService } from "src/app/common/components/notifications/notifiy.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PaymentNotificationService {
  private notificationUrl = environment.notification;

  constructor(
    private httpclient: HttpClient,
    private notifyService: NotifiyService
  ) {}

  sendStudentStatment(studentId: string) {
    const statementPath = "SendStatement";
    const body = JSON.stringify({ studentId });
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };
    this.httpclient
      .post(`${this.notificationUrl}${statementPath}`, body, httpOptions)
      .subscribe(
        (next) => {
          this.notifyService.createNotification({
            success: true,
            message: `Email sent`,
          });
        },
        (error) => {
          console.error(error);
             this.notifyService.createNotification({
               success: false,
               message: `Email send failed`,
             });
        }
      );
  }
}
