import { Pipe, PipeTransform } from '@angular/core';
import { StudentService } from '../students/services/student.service';

@Pipe({
  name: 'gradeStudentcountPipe'
})
export class gradeStudentcountPipe implements PipeTransform {

constructor()
{
  
}
  transform(value: any, args?: any): any {
    if(value === null || value === undefined )
      return 0;
    try{
  
      // var studentcount =this.studentService.GetStudentCountByClass(value)
      // console.log(studentcount)
      return 0;
    }catch{
      return 0
    }
  }

}
