import { Component, OnInit } from '@angular/core';
import { combineLatest, from, map, of, switchMap } from 'rxjs';
import { GradeService } from 'src/app/pages/grades/grade.service';
import { PaymentService } from '../../../pages/students/services/payment.service';
import { StudentviewmodelService } from '../../../pages/students/services/studentviewmodel.service';

@Component({
  selector: 'app-paymentsummary',
  templateUrl: './paymentsummary.component.html',
  styleUrls: ['./paymentsummary.component.scss']
})
export class PaymentsummaryComponent implements OnInit {
   
  constructor(public studentviewmodelService$: StudentviewmodelService) {
   }

  ngOnInit(): void {   
  }

}
