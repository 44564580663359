import { ErrorHandler, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StudentregistrationComponent } from '../studentregistration/studentregistration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../common/shared.module';
import { FeesComponent } from '../fees/fees.component';
import { PaymentmanagerComponent } from '../paymentmanager/paymentmanager.component';
import { WebcamModule } from 'ngx-webcam';
import { GradesModule } from 'src/app/pages/grades/grade.module';
import { StudentregistrationViewComponent } from '../components/studentregistration-view/studentregistration-view.component';
import { StudentregistrationGridComponent } from '../components/studentregistration-grid/studentregistration-grid.component';
import { RegistrationfeeblockscreenComponent } from '../components/registrationfeeblockscreen/registrationfeeblockscreen.component';
import { PaymentformComponent } from '../components/paymentform/paymentform.component';
import { StudentregistrationDetailsComponent } from '../components/studentregistration-details/studentregistration-details.component';
import { StudentregistrationGaurdianComponent } from '../components/studentregistration-gaurdian/studentregistration-gaurdian.component';
import { StudentregistrationCaptureimageComponent } from '../components/studentregistration-captureimage/captureimage.component';
import { StudentCardComponent } from '../components/student-card/student-card.component';
import { StudentEditComponent } from '../components/student-edit/student-edit.component';
import { StudentEditLoaderComponent } from '../components/student-edit/student-edit-loader.component';
import { PaymentGridComponent } from '../components/payment-grid/payment-grid.component';
import { StudentlistComponent } from '../studentlist/studentlist.component';
import { StudentsServices } from '../services/students.service';
import { EffectsModule } from '@ngrx/effects';
import { StudentEffects } from '../store/student.effects';
import { AppInlineLoader } from 'src/app/common/components/loader/loader-inline';



@NgModule({
  declarations: [
    StudentregistrationComponent,
    FeesComponent,
    PaymentmanagerComponent,
    StudentregistrationViewComponent,
    StudentregistrationGridComponent,
    RegistrationfeeblockscreenComponent,
    PaymentformComponent,
    PaymentGridComponent,
    StudentregistrationDetailsComponent,
    StudentregistrationGaurdianComponent,
    StudentregistrationCaptureimageComponent,
    StudentCardComponent,
    StudentEditComponent,
    StudentEditLoaderComponent,
    StudentlistComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    GradesModule,
    WebcamModule,
    AppInlineLoader,
    EffectsModule.forFeature([StudentEffects]),
  ],
  exports: [RegistrationfeeblockscreenComponent, PaymentformComponent],
  providers: [StudentsServices],
})
export class StudentModuleModule {}
 