import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { createStudentNumber } from "../../studentregistration/studentregistration.component";

@Component({
  selector: "app-studentregistration-details",
  template: `

    <form
      [formGroup]="registrationForm"
      (ngSubmit)="onSubmit()"
      class="form-max-control-70 margin-top-bottom-1em"
      novalidate="novalidate"
    >
      <div class="title-bar grid col-2">
        Personal Details
        <div>
          <img
            *ngIf="this.registrationForm.valid"
            src="assets/images/checkbox.svg"
          />
        </div>
      </div>

      <div class="form-control grid col-3">
        <div class="form-input">
          <input
            placeholder="Name"
            type="text"
            name="name"
            formControlName="name"
          />
          <span
            *ngIf="
              !this.registrationForm.pristine &&
              !this.registrationForm.get('name')?.valid
            "
          >
            Invalid name!
          </span>
        </div>
        <div></div>
        <div class="form-input">
          <input
            placeholder="Surname"
            type="text"
            name="surname"
            formControlName="surname"
          />
          <span
            *ngIf="
              !this.registrationForm.pristine &&
              !this.registrationForm.get('surname')?.valid
            "
          >
            Invalid Surname!
          </span>
        </div>
      </div>
      <div class="form-control grid col-3">
        <div class="form-input">
          <input
            placeholder="ID Number"
            type="text"
            name="Idnumber"
            formControlName="iDNumber"
          />
          <span
            *ngIf="
              !this.registrationForm.pristine &&
              !this.registrationForm.get('iDNumber')?.valid
            "
          >
            Invalid Id Number!
          </span>
        </div>
        <div class="form-input">
          <input placeholder="Email" name="email" formControlName="email" />
          <span
            *ngIf="
              !this.registrationForm.pristine &&
              !this.registrationForm.get('email')?.valid
            "
          >
            Invalid Email!
          </span>
        </div>
        <div class="form-input">
          <select formControlName="gender">
            <option>Choose Gender</option>
            <option *ngFor="let gender of genders">{{ gender }}</option>
          </select>

          <span
            *ngIf="
              !this.registrationForm.pristine &&
              !this.registrationForm.get('gender')?.valid
            "
          >
            Invalid Gender!
          </span>
        </div>
      </div>
      <div class="title-bar grid col-2">
        Contact Details
        <div>
          <img
            *ngIf="this.registrationForm.valid"
            src="assets/images/checkbox.svg"
          />
        </div>
      </div>

      <div class="form-control grid col-2">
        <div class="form-input">
          <textarea
            placeholder="Address"
            rows="5"
            formControlName="address"
          ></textarea>
          <span
            *ngIf="
              !this.registrationForm.pristine &&
              !this.registrationForm.get('address')?.valid
            "
          >
            Invalid Address!
          </span>
        </div>
      </div>
      <div class="form-control grid col-2">
        <div class="form-input max-width-200px">
          <input
            placeholder="Postal Code"
            type="text"
            name="postalcode"
            formControlName="postalCode"
          />
          <span
            *ngIf="
              !this.registrationForm.pristine &&
              !this.registrationForm.get('postalCode')?.valid
            "
          >
            Invalid PostalCode!
          </span>
        </div>
      </div>
      <div class="form-control grid col-3 ">
        <div></div>
      </div>
      <div class="form-control grid col-3 "></div>

      <div class="form-control grid col-4 button-md">
        <div></div>
        <button type="button" (click)="onCancel()" class="greybtn">
          Previous
        </button>
        <button type="submit" [disabled]="registrationForm.invalid">
          Next
        </button>
      </div>
    </form>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StudentregistrationDetailsComponent implements OnInit {
  registrationForm = new FormGroup({
    grade: new FormControl("", [Validators.required, Validators.minLength(3)]),
    name: new FormControl("", [Validators.required, Validators.minLength(3)]),
    studentNumber: new FormControl(""),
    surname: new FormControl("", [
      Validators.required,
      Validators.minLength(3),
    ]),
    gender: new FormControl("", [Validators.required]),
    iDNumber: new FormControl("", [
      Validators.required,
      Validators.minLength(8),
    ]), //What about Passports
    address: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
    ]),
    postalCode: new FormControl("", [
      Validators.required,
      Validators.maxLength(6),
    ]),
    email: new FormControl("", [Validators.required, Validators.email]),
  });

  @Input() selectedgrade: string | undefined;
  @Input() grade: string | undefined;
  @Input() numberofStudents: number = 0;
  @Output() onproceedstep = new EventEmitter<any>();
  @Output() cancel = new EventEmitter();
  genders = ["Male", "Female"];
  constructor() {}

  ngOnInit(): void {
    console.log(this.grade);
    this.registrationForm.patchValue({ grade: this.grade });
  }
  onSubmit() {
    const studentNumber = createStudentNumber(
      this.registrationForm.get("grade")?.getRawValue(),
      this.numberofStudents,
      this.registrationForm.get("name")?.getRawValue(),
      this.registrationForm.get("surname")?.getRawValue()
    );
    this.registrationForm.patchValue({ studentNumber: studentNumber });
    const studentdetails = { ...this.registrationForm.value };
    this.onproceedstep.emit(studentdetails);
  }
  onCancel() {
    console.log("cancel");
    this.cancel.emit();
  }
}
