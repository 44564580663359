export interface Payment {
  id: string;
  month: number;
  studentid: string;
  amount?: number;
  type: string;
  paymentdate: Date | undefined;
}

export function objecttoPayment(obj:any) :Payment{
  return {
    id: obj.id,
    month: obj.month,
    paymentdate: obj.paymentdate,
    studentid: obj.studentid,
    type: obj.type,
    amount: obj.amount,
  } as Payment;
};

export function objectToParmentArray(paymentobject: any): Payment[] {
  var payments = new Array<Payment>();
  paymentobject.items.forEach((pay: any) => {
    payments.push(objecttoPayment(pay));
  });
  return payments;
}

export class PaymentLedger{
  id!: string;
  studentid!: string;
  amount: number = 0;
  type!: string;
  paymentdate: Date | undefined;
  month!: { index: number; name: string };
}
export interface PaymentSummary {
  key: string;
  value: number;
}
