import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { select, Store } from "@ngrx/store";
import {
  BehaviorSubject,
  combineLatest,
  combineLatestAll,
  concatMap,
  filter,
  map,
  merge,
  mergeMap,
  Observable,
  of,
  Subscription,
  switchMap,
  tap,
} from "rxjs";
import { GradeService } from "src/app/pages/grades/grade.service";

import { PaymentService } from "../services/payment.service";
import { Istudent, StudentService } from "../services/student.service";
import { StudentviewmodelService } from "../services/studentviewmodel.service";
import { SelectStudent } from "../store/student.actions";

import { loadPaymentHistory } from "../../payments/stores/payment.actions";
import * as FromPaymentReducer from "../../payments/stores/payment.reducers";
import * as FromStudentReducer from "../store/student.reducers";
import * as FromGradeReducer from "../../grades/store/grade.reducers";
import * as FromGradeAction from "../../grades/store/grade.actions";
import { IGrade } from "../../grades/igrade.model";
import {
  arrearsCalculation,
  StudentPaymentDetailsStore,
} from "./store/filter-component.store";

@Component({
  selector: "app-fees",
  templateUrl: "./fees.component.html",
  styleUrls: ["./fees.component.scss"],
  providers: [StudentPaymentDetailsStore],
})
export class FeesComponent implements OnInit {
  [x: string]: any;

  constructor(
    private studentStore: Store<FromStudentReducer.StudentState>,
    private paymentStore: Store<FromPaymentReducer.PaymentState>,
    private gradeStore: Store<FromGradeReducer.GradeState>,
    private paymentsDetailstore: StudentPaymentDetailsStore
  ) {}

  grades$ = this.gradeStore.pipe(select(FromGradeReducer.getGrades));
  paymentdetailsState$ = this.paymentsDetailstore.getstate$;
  studentsState$ = this.studentStore
    .pipe(select(FromStudentReducer.getStudentState))
    .pipe(
      tap(({ selected }) => {
        if (selected)
          this.paymentsDetailstore.loadPaymentInformation(selected?.id);
      })
    );
  studentPaymentDetails$ = this.paymentStore.select(
    FromPaymentReducer.getStudentPaymentDetails
  );
  ngOnInit(): void {
    this.gradeStore.dispatch(FromGradeAction.loadGrade());
  }

  filterStudent(id: string) {
    this.studentStore.dispatch(SelectStudent({ studentId: id }));
  }
}
