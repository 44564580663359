import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Subject } from 'rxjs/internal/Subject';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderSubject = new Subject<boolean>();

  private sidebarSubject = new BehaviorSubject<boolean>(true);
  
  public sidebarSubject$ = this.sidebarSubject.asObservable();

  public loaderSubject$ = this.loaderSubject.asObservable();

  constructor() { }
  public setloader(load:boolean)
  {
    this.loaderSubject.next(load);
  }
  public setsidebar(enable :boolean)
  {
    console.log("Setting sidebar ")
    this.sidebarSubject.next(enable);
  } 
}
