import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { filter, map, Observable, observable, of, switchMap, tap } from 'rxjs';
import { GradeService } from 'src/app/pages/grades/grade.service';
import { getGrades, getGradeState, GradeState } from 'src/app/pages/grades/store/grade.reducers';
import { Istudent, StudentService } from '../../services/student.service';

@Component({
  selector: "app-studentregistration-grid",
  templateUrl: "./studentregistration-grid.component.html",
  styleUrls: ["./studentregistration-grid.component.scss"],
})
export class StudentregistrationGridComponent implements OnInit {
  @Input() students!: Istudent[];
  @Output() filterGrade = new EventEmitter<string>();
  @Output() filterStudentName = new EventEmitter<string>();
  @Output() filterStudentNumber = new EventEmitter<string>();
  @Output() FormSubmit = new EventEmitter<Istudent>();

  filtermode = false;
  activeSearch: { searchtext: string; activetype: string } = {
    searchtext: "",
    activetype: "",
  };
  filterFormControl = new FormGroup({
    grade: new FormControl(""),
    firstName: new FormControl(""),
    lastName: new FormControl(""),
  });

  gradestate$ = this.store.pipe(select(getGradeState));
  constructor(
      public store: Store<GradeState>
  ) {}

  selectedstudent$: Observable<Istudent> | undefined;
  ngOnInit(): void {}

  onSubmit(student: any)
  {
    console.log(student)
  }

  updateStudent($event: Istudent) {
    this.FormSubmit.emit($event);

    // this.FormSubmit.emit($event)
    //this.studentService.update($event, "studentgrid");
     this.cancelstudentselection();
  }
  doubleClick() {
    this.filtermode = !this.filtermode;
  }

  onchangestudentnumber($event: any) {
    this.filterStudentNumber.emit($event.target.value);
  }
  selectstudent(studentid: string) {
    this.selectedstudent$ = of(this.students)?.pipe(
      map((s) => s.filter((student) => student.id === studentid)[0])
    );
  }
  cancelstudentselection() {
    this.selectedstudent$ = undefined;
  }
  onchangestudentname($event: any) {
    this.filterStudentName.emit($event.target.value);
  }

  filter(searchtearm: any) {
    this.filterGrade.emit(searchtearm.target.value);
  }
}
