import { createAction, props } from "@ngrx/store";
import { Iexpense } from "../interfaces/Iexpense";

export const loadExpense = createAction("[Expense Component], LoadExpense");

export const loadExpenseSuccess = createAction(
  "[Expense Component], LoadSuccessExpense",
  props<{ Expenses: Iexpense[] }>()
);
export const loadExpenseFailed = createAction(
  "[Expense Component], LoadFailedExpense",
  props<{ err: any }>()
);
export const SelectExpense = createAction(
  "[Expense Component], SelectExpense",
  props<{ expenseId: string }>()
);
export const SaveExpense = createAction(
  "[Expense Component], SaveExpense",
  props<{ expense: Iexpense }>()
);
export const SaveExpenseSuccess = createAction(
  "[Expense Component], SaveSuccessExpense"
);
export const SaveExpenseFailed = createAction(
  "[Expense Component], SaveFailedExpense",
  props<{ err: any }>()
);

