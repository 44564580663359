import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { progressmodel } from 'src/app/pages/students/studentregistration/studentregistration.component';

@Component({
  selector: "app-step-breadcrumb",
  template: `
    <ul
      *ngIf="breadcrumbsprogress$ | async as breadcrumbsprogress"
      class=" form-stepper form-stepper-horizontal text-center mx-auto pl-0"
    >
      <li
        *ngFor="let item of breadcrumbsprogress; let i = index"
        class="form-stepper-active text-center form-stepper-list"
        [ngClass]="[item.completed ? 'form-stepper-completed' : '']" step="i">
        <a class="mx-2 grid">
          <span class="form-stepper-circle">
            <span>{{ i + 1 }}</span>
          </span>
          <label class="label">{{ item.title }}</label>
        </a>
      </li>
    </ul>
  `,
  styleUrls: ["./multistepbreadcrumb.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultistepbreadcrumbComponent implements OnInit {
  @Input() breadcrumbsprogress$: Observable<progressmodel[]> = new Observable<progressmodel[]>();

  constructor() {
    this.breadcrumbsprogress$?.subscribe((e) => {
      console.log(e);
    },(err=>{
      console.log(err)
    }));
  }

  ngOnInit() {}
}
