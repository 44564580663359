import { CommonModule } from "@angular/common";
import { Component, Input, OnInit } from "@angular/core";
import { SharedModule } from "../../../common/shared.module";
import { PaymentLedger } from "../Payments.model";

@Component({
  selector: "app-payment-grid",
  template: `
    <div class="height-400 scroll-y padding-2em-sides  margin-top-2em ">
      <app-table [data]="studentpayments">
        <ng-template #headers>
          <th>Month</th>
          <th>Fee Payable</th>
          <th>Payment</th>
          <th>Payment Date</th>
        </ng-template>
        <ng-template #rows let-row>
          <td [ngClass]="{ linethrough: startMonth > row.month?.index }">
            {{ row.month?.name }}
          </td>
          <td [ngClass]="{ linethrough: startMonth > row.month?.index }">
            {{ gradeFee | currency : "R " }}
          </td>
          <td *ngIf="startMonth > row.month?.index; else avaliablecolumn">
            N/A
          </td>

          <ng-template #avaliablecolumn>
            <ng-container *ngIf="row.amount === undefined; else valueRow">
              <td>Payment Due</td>
            </ng-container>
            <ng-template #valueRow>
              <td>{{ row.amount | currency : "R" }}</td>
            </ng-template>
          </ng-template>
          <td *ngIf="startMonth > row.month?.index; else availabledate">N/A</td>
          <ng-template #availabledate>
            <td>{{ row.paymentdate | date : "d MMMM yyyy" }}</td>
          </ng-template>
        </ng-template>
      </app-table>
    </div>
  `,
})
export class PaymentNewGridComponent implements OnInit {
  constructor() {}
  @Input() studentpayments: PaymentLedger[] = [];
  @Input() startMonth: number = 1;
  @Input() gradeFee: number = 0;
  ngOnInit() {}
}
