import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { LoaderService } from 'src/app/common/services/loader.service';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SigninComponent implements OnInit,OnDestroy {

  constructor(private router: Router
    ,public authservice: AuthService
    ,private loaderService : LoaderService) 
  {
    
  }
  ngOnDestroy(): void {
    this.loaderService.setsidebar(true)
  }

  ngOnInit(): void {
    this.loaderService.setsidebar(false)
    if(this.authservice.isloggedIn$.pipe(take(1)))
    {
      this.router.navigate(['/home']);
    }
  }

}
