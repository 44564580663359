import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { bufferWhen, filter, fromEvent, interval, pipe,merge,map } from 'rxjs';
import { GradeService } from './pages/grades/grade.service';
import { LoaderService } from './common/services/loader.service';
import { Store } from '@ngrx/store';
import  * as fromStudentAction from './pages/students/store/student.actions';
import { loadGrade } from './pages/grades/store/grade.actions';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {
  title = 'schooladmin';

  constructor(private gardservice: GradeService, 
    store: Store){
       store.dispatch(loadGrade())
       store.dispatch(fromStudentAction.loadStudents());
  }
  
  ngOnInit(): void {
  //  this.gardservice.GetGrades();
  }
   ACTIVE_EVENTS = [
    'click', 'scroll', 'contextmenu', 'dblclick', 'mousemove',
  ];

// you can add as many events as you want to define "being inactive"

   windowinactive= merge(...this.ACTIVE_EVENTS
    .map(event => fromEvent(document, event))).pipe(
    bufferWhen(() => interval(100000)),
    filter(events => events.length === 0),
    ).subscribe(_=>{
      console.log("Show inactive")

    })
  
}
