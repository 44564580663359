import { Injectable } from "@angular/core";
import { async } from "@angular/core/testing";
import { Actions, createEffect, Effect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, switchMap } from "rxjs";
import { StudentsServices } from "../services/students.service";
import * as studentActions from "./student.actions";

@Injectable()
export class StudentEffects {
  constructor(
    private studentsService: StudentsServices,
    private action$: Actions
  ) {}

  getStudents$ = createEffect(() => {
    return this.action$.pipe(
      ofType(studentActions.loadStudents),
      concatMap(() =>
        this.studentsService.getStudents().pipe(
          map((studentsResponse) =>
            studentActions.loadStudentSuccess({ students: studentsResponse })
          ),
          catchError(async (err) =>
            studentActions.loadStudentsFail({ err: err })
          )
        )
      )
    );
  });

  saveStudent$ = createEffect(() => {
    return this.action$.pipe(
      ofType(studentActions.SaveStudent),
      map((payload) => payload.student),
      switchMap((student) =>
        this.studentsService.createStudent(student).pipe(
          switchMap((studentresponse) => [
            studentActions.SaveStudentSuccess({ student: studentresponse }),
            studentActions.loadStudents(),
          ]),
          catchError(async (error) =>
            studentActions.SaveStudentFailed({ err: error })
          )
        )
      )
    );
  });
  // @Effect()
  // saveStudent$ = this.action$.pipe(
  //     ofType(studentActions.SaveStudent),
  //     map((payload) => payload.student),
  //     switchMap((student) =>
  //       this.studentsService.createStudent(student).pipe(
  //         switchMap(async (studentresponse) => studentActions.SaveStudentSuccess({ student: studentresponse })),
  //         catchError(async (error) =>
  //           studentActions.SaveStudentFailed({ err: error })
  //         )
  //       )
  //     )
  //   )
}
