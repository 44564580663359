import { Injectable } from "@angular/core";
import { Subject, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class NotifiyService {
  private requestHandlerSubject = new Subject<RequestHandlerResponse>();

  public requestHandler$ = this.requestHandlerSubject.asObservable();

  constructor() {}

  public createNotification({ success, message }: RequestHandlerResponse) {
    this.requestHandlerSubject.next({
      success: success,
      message: message,
    } as RequestHandlerResponse);
    setTimeout(() => {
      this.requestHandlerSubject.next({
        success: undefined,
        message: "",
      } as RequestHandlerResponse);
    }, success?10000:20000);
  }
}

export class ErrorHandler {
  constructor(private notifiyService: NotifiyService) {}

  handleError(error: RequestHandlerResponse) {
     this.notifiyService.createNotification({
      success: error.success,
      message: error.message,
    });
    return throwError(() => {
      return error.message;
    });
  }
}

export interface RequestHandlerResponse {
  success: boolean |undefined;
  message: string;
}
