import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { Istudent } from "../../services/student.service";
import * as fromStudentAction from "../../store/student.actions";
@Component({
  selector: "app-student-edit",
  templateUrl: "./student-edit.component.html",
  styleUrls: ["./student-edit.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentEditComponent implements OnInit {

  studentForm = new FormGroup({
    id: new FormControl(""),
    studentNumber: new FormControl(""),
    address: new FormControl(""),
    allergies: new FormControl(""),
    doctorContact: new FormControl(""),
    email: new FormControl("",Validators.email),
    employeeContact: new FormControl(""),
    employer:new FormControl(""),
    familyDoctor: new FormControl(""),
    familyDoctorNumber: new FormControl(""),
    gaurdianName: new FormControl("",Validators.required),
    gaurdianSurname: new FormControl("",Validators.required),
    gender: new FormControl(""),
    grade: new FormControl(""),
    iDNumber: new FormControl(""),
    gaurdianIdNumber: new FormControl("",Validators.required),
    illness: new FormControl(""),
    medicalAidGroup: new FormControl(""),
    medicalAidNumber: new FormControl(""),
    mobileTelephone: new FormControl(""),
    name: new FormControl(""),
    nextOfKin: new FormControl(""),
    nextOfKinNumber: new FormControl(""),
    nextOfKinRelation: new FormControl(""),
    postalCode: new FormControl(""),
    relationship: new FormControl(""),
    surname: new FormControl(""),
    telephone: new FormControl(""),
    startdate: new FormControl(""),
    image: new FormControl(""), 
  });
  @Input() load: boolean = false;
  @Input() student$: Observable<Istudent> | undefined;
  @Output() cancel = new EventEmitter()
  @Output() submit = new EventEmitter<Istudent>()

  apiendpoint = environment.apiEndpoint
  capturedImage:string=""
  capturemode: boolean = false;
  mouseover_:boolean =false
  mouseover() {
    this.mouseover_ = true
  } 
  mouseleave(){
    this.mouseover_ = false
  }
  
  captureimage(event:any) {
    this.capturedImage = event
  }
  saveimage() {
    this.studentForm.patchValue({"image":this.capturedImage})
    this.capturemode = false
  }

  onSubmit() {
    const student = { ...this.studentForm.getRawValue() } as Istudent;

    const studentForm = {
      id: student.id,
      studentNumber: student.studentNumber,
      address: student.address,
      allergies: student.allergies,
      doctorContact: student.doctorContact,
      email: student.email,
      employeeContact: student.employeeContact,
      employer: student.employer,
      familyDoctor: student.familyDoctor,
      familyDoctorNumber: student.familyDoctorNumber,
      gaurdianName: student.gaurdianName,
      gaurdianSurname: student.gaurdianSurname,
      gender: student.gender,
      grade: student.grade,
      iDNumber: student.iDNumber,
      gaurdianIdNumber: student.gaurdianIdNumber,
      illness: student.illness,
      medicalAidGroup: student.medicalAidGroup,
      medicalAidNumber: student.medicalAidNumber,
      mobileTelephone: student.mobileTelephone,
      name: student.name,
      nextOfKin: student.nextOfKin,
      nextOfKinNumber: student.nextOfKinNumber,
      nextOfKinRelation: student.nextOfKinRelation,
      postalCode: student.postalCode,
      relationship: student.relationship,
      surname: student.surname,
      telephone: student.telephone,
      startdate: student.startdate,
      image: student.image,
    };
    console.log(studentForm);
    this.store.dispatch(fromStudentAction.SaveStudent({ student: studentForm }));
    this.onclose();
  }
  onclose(){
    this.cancel.emit()
  }
  constructor(private store: Store) {}

  ngOnInit(): void {

    this.student$?.subscribe(student =>{
        this.studentForm.patchValue(student)
    })
  }
}
