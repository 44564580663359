import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { SigninComponent } from "./pages/auth/signin/signin.component";
import { AuthGaurd } from "./gaurds/auth.guard";
import { GradeComponent } from "./pages/grades/grade/grade.component";
import { HomeComponent } from "./pages/home/home.component";
import { InvoiceComponent } from "./pages/invoice/invoicePage/invoice.component";
import { FeesComponent } from "./pages/students/fees/fees.component";
import { PaymentmanagerComponent } from "./pages/students/paymentmanager/paymentmanager.component";
import { StudentregistrationGridComponent } from "./pages/students/components/studentregistration-grid/studentregistration-grid.component";
import { StudentregistrationComponent } from "./pages/students/studentregistration/studentregistration.component";
import { StudentlistComponent } from "./pages/students/studentlist/studentlist.component";
import { TeacherComponent } from "./pages/teachers/teacher.component";
import { ExpenseComponent } from "./pages/expense/expense.component";
import { PaymentsComponent } from "./pages/payments/payments.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
    canActivate: [AuthGaurd],
  },
  {
    path: "login",
    component: SigninComponent,
  },
  {
    path: "registration",
    component: StudentregistrationComponent,
    canActivate: [AuthGaurd],
  },
  {
    path: "fees",
    component: FeesComponent,
    canActivate: [AuthGaurd],
  },
  {
    path: "students",
    component: StudentlistComponent,
    canActivate: [AuthGaurd],
  },
  {
    path: "teachers",
    component: TeacherComponent,
    canActivate: [AuthGaurd],
  },
  {
    path: "expense",
    component: ExpenseComponent,
    canActivate: [AuthGaurd],
  },
  {
    path: "invoice",
    component: InvoiceComponent,
    children: [
      {
        path: "invoice/:studentid",
        component: InvoiceComponent,

        canActivate: [AuthGaurd],
      },
    ],
    canActivate: [AuthGaurd],
  },

  {
    path: "payment/:studentid",
    component: PaymentsComponent,
    children: [
      {
        path: "user",
        component: FeesComponent,
      },
    ],
    canActivate: [AuthGaurd],
   },
  // {
  //   path: "payment/:studentid",
  //   component: PaymentmanagerComponent,
  //   children: [
  //     {
  //       path: "user",
  //       component: FeesComponent,
  //     },
  //   ],
  //   canActivate: [AuthGaurd],
  // },

  { path: "**", redirectTo: "/login" },
  { path: "*/*", redirectTo: "/login" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
