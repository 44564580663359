import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { WebcamImage, WebcamInitError } from "ngx-webcam";
import { Observable, Subject } from "rxjs";

@Component({
  selector: "app-studentregistration-captureimage",
  template: `
    <div class="camera-container">
      <ng-template #loading>
        <span>Loading camera...</span>
      </ng-template>
      <ng-container *ngIf="webcamImage === undefined; else imagecapture">
        <div class="imageholder" >
          <webcam
            [trigger]="invokeObservable"
            (imageCapture)="captureImg($event)"
            [width]="300"
            [height]="168"
            (initError)="handleInitError($event)"
          ></webcam>
        </div>
        <button (click)="getSnapshot()">
          <span> Capture Image</span>
          <img
            alt="camera"
            class="capture-icon"
            src="assets/images/camera.svg"
          />
        </button>
      </ng-container>
      <ng-template #imagecapture>
        <div class="imageholder">
          <img [src]="webcamImage?.imageAsDataUrl" alt="capturedimage"/>
        </div>
        <button (click)="resetCamera()">  <span> Retake Image</span>
          <img
            alt="camera"
            class="capture-icon"
            src="../assets/images/camera.svg"
          /></button>
      </ng-template>
    </div>
    <div class="col-12" *ngIf="webcamImage != null"></div>
  `,
  styleUrls: ["./studentregistration-captureimage.component.scss"],
})
export class StudentregistrationCaptureimageComponent implements OnInit {
  @Output() oncaptureimage = new EventEmitter<string>();
  private trigger: Subject<any> = new Subject();
  public webcamImage: WebcamImage | undefined;
  private nextWebcam: Subject<any> = new Subject();
  sysImage = "";
  ngOnInit() {}

  public getSnapshot(): void {
    this.trigger.next(void 0);
  }

  resetCamera() {
    this.webcamImage = undefined;
  }
  public captureImg(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.sysImage = webcamImage!.imageAsDataUrl;
    if (webcamImage !== undefined) {
      this.oncaptureimage.emit(webcamImage.imageAsDataUrl);
    }
  }
  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  handleInitError($event: WebcamInitError) {
    console.log($event);
  }

  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
}
