import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
} from "@angular/core";

@Component({
  selector: "app-sidemodalcontainer",
  template: `
    <section class="model-section" *ngIf="show">
      <div class="model-container grid">
        <section class="model-header">
          <h3>{{ title }}</h3>
        </section>
        <section class="model-content">
          <ng-container
            *ngTemplateOutlet="content || defaultTemplate"
          ></ng-container>
        </section>
        <section class="model-action" *ngIf="showControls">
          <div class="form-control grid col-4 button-md">
            <button 
              type="button"
              class="greybtn"
              style="margin-right: 2.5em;"
              (click)="closemodel()">
              Close
            </button>
            <button type="button" style="padding: 0.5em 2em;">submit</button>
          </div>
        </section>
      </div>
    </section>

    <ng-template #defaultTemplate let-content> </ng-template>
  `,
  styleUrls: ["./sidemodalcontainer.component.scss"],
})
export class SidemodalcontainerComponent implements OnInit {
  constructor() {}

  @Input() title: string = "";
  @Input() show: boolean = false;
  @Input() showControls: boolean = false;
  @Output() cancel = new EventEmitter()
  @ContentChild("content") content: TemplateRef<any> | undefined;
  
  ngOnInit(): void {}

  closemodel() {
    this.cancel.emit();
    this.show = false;
  }
}
export class ModelAction
{
  showmodel :boolean =false
  title = ""
  openModel(title: string){
      this.title = title
      this.showmodel = true
  }
  
  closeModel()
  {
    this.title = ""
    this.showmodel = false
  }
}
