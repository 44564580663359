import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Iteacher } from '../../interface/iteacher';

@Component({
  selector: "app-teacher-edit",
  templateUrl: "./teacher-edit.component.html",
  styleUrls: ["./teacher-edit.component.css"],
})
export class TeacherEditComponent implements OnInit {
  teacherForm = new FormGroup({
    id: new FormControl(""),
    address: new FormControl("",Validators.required),
    email: new FormControl("", Validators.email),
    gender: new FormControl(""),
    iDNumber: new FormControl("", Validators.required),
    mobileTelephone: new FormControl("", Validators.required),
    name: new FormControl("", Validators.required),
    postalCode: new FormControl("",Validators.required),
    surname: new FormControl("", Validators.required),
    startdate: new FormControl(""),
  });
  genders = ["Male", "Female"];
  @Output() canceled = new EventEmitter();
  @Output() submited = new EventEmitter<Iteacher>();
  @Input() teacher! : Iteacher | undefined
  constructor() {}

  ngOnInit() {
    if(this.teacher !== undefined || this.teacher !== null)
    {
      this.teacherForm.patchValue({
        id: this.teacher?.id,
        address: this.teacher?.address,
        email: this.teacher?.email,
        gender: this.teacher?.gender,
        iDNumber: this.teacher?.iDNumber,
        mobileTelephone: this.teacher?.mobileTelephone,
        name: this.teacher?.name,
        postalCode: this.teacher?.postalCode,
        surname: this.teacher?.surname,
      });
    }
  }

  onSubmit() {
    var teacher = this.teacherForm.getRawValue() as Iteacher
    this.submited.emit(teacher)
  }
  onclose() {
    this.canceled.emit()
  }
}
