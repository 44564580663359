import { Component, ContentChild, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit {
defaultHeaderTemplate: TemplateRef<any> | undefined;

  constructor() { }
  
  @ContentChild('title') titleTemplate: TemplateRef<any> | undefined;
  @ContentChild('body') bodyTemplate: TemplateRef<any> | undefined;
  
  ngOnInit(): void {
  }

}
