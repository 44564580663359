import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GradeComponent } from './grade/grade.component';
import { GradeEditComponent } from './grade-edit/grade-edit.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../../common/shared.module';
import { GradeRoutingModule } from './grade-routing.module';
import { GradeviewComponent } from './gradeview/gradeview.component';
import { IconsComponent } from './gradeview/icons/icons.component';
import { gradeStudentcountPipe } from './studentcount.pipe';
import { EffectsModule } from '@ngrx/effects';
import { GradesEffects } from './store/grade.effects';



@NgModule({
  declarations: [
    GradeComponent,
    GradeEditComponent,
    GradeviewComponent,
    IconsComponent,
    gradeStudentcountPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    GradeRoutingModule,
    EffectsModule.forFeature([GradesEffects])
  ],
  exports:[GradeComponent,IconsComponent,GradeEditComponent,GradeviewComponent,GradeRoutingModule,gradeStudentcountPipe]
})
export class GradesModule { }
