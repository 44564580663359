import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gradeview',
  templateUrl: './gradeview.component.html',
  styleUrls: ['./gradeview.component.css']
})
export class GradeviewComponent implements OnInit {

  grades:{ icon: string; title: string; }[]  = [
    {icon:"bee",title:"Bumble Bee "},
    {icon:"bear",title:"Bunny Bears "},
    {icon:"jellyfish",title:"Jelly Fish "},
    {icon:"bag",title:"Grade R "},
    {icon:"bells",title:"Jingle Bells "}
  ]
  constructor() { }

  ngOnInit() {
    
  }

}
