import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { Router } from "@angular/router";
import {
  Observable,
  
} from "rxjs";
import { Ipager } from "src/app/common/components/apptable/apptablepagination.component";
import { ModelAction } from "src/app/common/components/sidemodalcontainer/sidemodalcontainer.component";
import { Iexpense } from "../../interfaces/Iexpense";

@Component({
  selector: "app-expense-grid",
  templateUrl: "./expense-grid.component.html",
  styleUrls: ["./expense-grid.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseGridComponent implements OnInit {
  @Input() expenselist: Iexpense[] | undefined;
  @Output() onSelectExpense = new EventEmitter<any>();
  @Output() onCreateExpense = new EventEmitter<any>();
  @Input()
  pager$: Observable<Ipager> | undefined;
  @Output() paginate = new EventEmitter<number>();

  currentMonth = new Date().getMonth();
  constructor(private router: Router) {}

  ngOnInit() {}
  onpaginate(page: number) {
    this.paginate.emit(page);
  }


  public selectExpense(expense: Iexpense) {
    this.onSelectExpense.emit(expense);
  }

  AddExpense() {
    this.onCreateExpense.emit({} as Iexpense);
  }
}
