import { Component, Input, OnInit } from '@angular/core';
import { Payment, PaymentService } from '../../services/payment.service';
import { Istudent } from '../../services/student.service';

@Component({
  selector: 'app-registrationfeeblockscreen',
  templateUrl: './registrationfeeblockscreen.component.html',
  styleUrls: ['./registrationfeeblockscreen.component.scss']
})
export class RegistrationfeeblockscreenComponent implements OnInit {

  constructor(public paymentService:PaymentService, ) { }
  @Input() showpop :boolean | undefined 
  @Input() requiredFee: number= 0
  @Input() student:Istudent | undefined

  ngOnInit(): void {
   
  }

  async submit(payment:any)
  {
    const fee = Number.parseInt(payment)
    //get credit if applicable
    if(fee === this.requiredFee )
    {

     var payment_object =  { 
               id:"",
              month:-1,
              paymentdate: Date.now(),
              studentid:this.student?.id,
              type:"r_fees",
              amount:fee,
      } as unknown as Payment
     await this.paymentService.feepayment(payment_object)
      window.location.reload();
      if(this.student?.id !== undefined){
        //this.paymentService.getpaymentsObserverable(this.student?.id)
        //this.showpop =  this.paymentService.isregistration_paid(this.student?.id)
      }
    }
  }
}
