import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { concatMap, map, switchMap } from "rxjs";
import { PaymentLedgerService } from "../services/payment.ledger.service";
import * as FromPaymentActions from "./payment.actions";

@Injectable()
export class PaymentEffects {
  constructor(
    private paymentservice: PaymentLedgerService,
    private actions$: Actions
  ) {}

  loadPaymentEffects$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FromPaymentActions.loadPaymentHistory),
      switchMap(({ studentId }) =>
        this.paymentservice.getpaymentsObserverable(studentId).pipe(
          map((payments) =>
            FromPaymentActions.loadPaymentHistorySuccess({
              Payments: payments,
              studentId: studentId,
            })
          )
        )
      )
    )
  );
}
