import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpenseComponent } from '../expense.component';
import { SharedModule } from 'src/app/common/shared.module';
import { ExpenseEditComponent } from '../component/expense-edit/expense-edit.component';
import { ExpenseGridComponent } from '../component/expense-grid/expense-grid.component';
import { ExpensefilterComponent } from '../component/expensefilter/expensefilter.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ExpenseDisplaytileComponent } from '../component/expenseDisplaytile/expenseDisplaytile.component';
import { EffectsModule } from '@ngrx/effects';
import { ExpenseEffects } from '../store/expense.effect';
import { ExpenseNewService } from '../services/expense-s.service';


@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    EffectsModule.forFeature([ExpenseEffects]),
  ],
  exports: [ExpenseComponent],
  declarations: [
    ExpenseComponent,
    ExpenseEditComponent,
    ExpenseGridComponent,
    ExpensefilterComponent,
    ExpenseDisplaytileComponent,
  ],
  providers: [ExpenseNewService],
})
export class ExpenseModule {}
