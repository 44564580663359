import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component  
({
    selector:'app-table-pagination',
    template: `
    <section class="grid gridinfinit">
        <span>Showing {{page}} to {{totalPages}} of {{totalrows}} entries</span>
        <div class="grid col-2 pager">
            <a href="javascript:(0)"  [ngClass]="{'nopage': page<=1}" (click)="onPagenav(-1)" ><span> </span><img src="/assets/images/angle-double-left.svg"/></a>
            <a  href="javascript:(0)" [ngClass]="{'nopage': page == totalPages}"  (click)="onPagenav(1)"> <span> </span><img src="/assets/images/angle-double-right.svg"/> </a>
        </div>
    </section>
    `,
    styleUrls: ['./apptable.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppTablePaginationComponent implements OnInit {

    @Input() totalrows: number =0
    @Input() page: number=0
    @Input() perpage: number=1
    @Input() totalPages= 0
    @Output() pagenav= new EventEmitter<number>()
    constructor(){

    }
    
    ngOnInit(): void {
      
    }
    onPagenav(navpage:number)
    {
        this.pagenav.emit(navpage)
    }
    
}

export interface Ipager {
  page: number;
  totalPages: number
  perpage: number;
  totalItems: number;

}