<ng-container *ngIf="student$ | async as student;">
    <ng-template #capture>
        <app-studentregistration-captureimage (oncaptureimage)="captureimage($event)"></app-studentregistration-captureimage>
        <div class="form-control grid col-4 button-md" style=" margin-top: 8em;">
            <button type="button" class="greybtn" style="margin-right: 2.5em;" (click)="capturemode = false">
                Close
            </button>
            <button type="button" (click)="saveimage()" *ngIf="capturedImage.length>0;" style="padding: 0.5em 2em;">Save</button>
        </div>
    </ng-template>
    <form [formGroup]="studentForm" (ngSubmit)="onSubmit()" *ngIf="!capturemode;else capture" class="form-max-control-70 margin-top-bottom-1em" novalidate="novalidate">
        <section class="grid col-2">
            <div class="grid profile-section" style="justify-items: flex-start;justify-self: baseline">
                <span class="student-title-ms">Illness:
                    {{student.illness | emptyna }}</span>
                <span class="student-title-ms">Medical Group:
                    {{student.medicalAidGroup | emptyna }}</span>
                <span class="student-title-ms">Doctor:
                    {{student.familyDoctor | emptyna }}</span>
                <span class="student-title-ms"></span>
                <span class="student-title-ms">Next of Kin:
                    {{student.nextOfKin | emptyna }}</span>
                <span class="student-title-ms">Next of Kin Contact:
                    {{student.nextOfKinNumber | emptyna }}</span>
            </div>
            <section class="profile-section grid">
                <ng-template #noimage>
                    <img *ngIf="studentForm" (click)="capturemode=true" class="round-image" src="assets/images/portrait.svg" alt="profile_image"></ng-template>
                    <ng-container *ngIf="student.image !== null;else noimage">
                        <img *ngIf="studentForm" (mouseover)="mouseover()" (mouseleave)="mouseleave()" (click)="capturemode=true" class="round-image" [src]="mouseover_?'../assets/images/portrait.svg'
                                                             : apiendpoint+'/api/files/students/'+student.id+'/'+student.image" alt="profile_image"></ng-container>
                        <span class="student-title">
                            {{student?.name}}
                            {{student?.surname}}</span>
                        <span class="student-title-ms">Student number:
                            {{student?.studentNumber}}</span>
                        <span class="student-title-ms">{{student?.grade}}</span>
                    </section>
                </section>
                <section>
                    <h3>Edit form</h3>
                    <div class="form-control grid col-3">
                        <div class="form-input">
                            <label for="email" *ngIf="studentForm.get('email')?.getRawValue()?.length > 0">Email</label>
                            <input placeholder="Email Address" [disabled]="true" type="email" name="email" id="email" formControlName="email"/>
                            <span *ngIf="studentForm.get('email')?.errors">
                                Invalid email!
                            </span>
                        </div>
                        <div class="form-input">
                            <label for="gaurdianName" *ngIf="studentForm.get('gaurdianName')?.getRawValue()?.length > 0">Gaurdian Name</label>
                            <input placeholder="Gaurdian Name" type="text" name="gaurdianName" id="gaurdianName" formControlName="gaurdianName"/>
                            <span *ngIf="true">
                                Invalid Gaurdian name!
                            </span>
                        </div>
                        <div class="form-input">
                            <label for="gaurdianSurname" *ngIf="studentForm.get('gaurdianSurname')?.getRawValue()?.length > 0">Gaurdian Surname</label>
                            <input placeholder="Gaurdian Surname" type="text" name="gaurdianSurname" id="gaurdianSurname" formControlName="gaurdianSurname"/>
                            <span *ngIf="true">
                                Invalid Gaurdian Surname!
                            </span>
                        </div>
                        <div class="form-input">
                            <label for="gaurdianIdNumber" *ngIf="studentForm.get('gaurdianIdNumber')?.getRawValue()?.length > 0">Gaurdian ID Number</label>
                            <input placeholder="Gaurdian ID number" type="text" name="gaurdianIdNumber" id="gaurdianIdNumber" formControlName="gaurdianIdNumber"/>
                            <span *ngIf="true
                                    ">
                                Invalid Gaurdian IdNumber!
                            </span>
                        </div>
                        <div class="form-input">
                            <label for="mobileTelephone" *ngIf="studentForm.get('mobileTelephone')?.getRawValue()?.length > 0">Telephone Number</label>
                            <input placeholder="Telephone number" type="text" name="mobileTelephone" id="mobileTelephone" formControlName="mobileTelephone"/>
                            <span *ngIf="studentForm.get('mobileTelephone')?.errors">
                                Invalid Telephone!
                            </span>
                        </div>

                        <div></div>
                        <div class="form-input">
                            <label for="address" *ngIf="studentForm.get('address')?.getRawValue()?.length > 0">Address</label>
                            <textarea placeholder="Address" type="text" name="address" id="address" formControlName="address"></textarea>
                            <span *ngIf="studentForm.get('Address')?.errors" >
                                Invalid Address Contact!
                            </span>
                        </div>
                        <section></section>
                        <section></section>
                        <div class="form-input">
                            <label for="postalCode" *ngIf="studentForm.get('postalCode')?.getRawValue()?.length > 0">Postal code</label>
                            <input placeholder="Postal code" type="text" name="postalcode" id="postalCode" formControlName="postalCode"/>
                            <span *ngIf="studentForm.get('postalCode')?.errors">
                                Invalid Postal code!
                            </span>
                        </div>
                    </div>
                </section>
            <section class="model-action" *ngIf="!capturemode">
                <div class="form-control grid col-4 button-md">
                    <button type="button" class="greybtn" style="margin-right: 2.5em;" (click)="onclose()">
                        Close
                    </button>
                    <button type="submit" style="padding: 0.5em 2em;" >submit</button>
                </div>
            </section>

            </form>
        </ng-container>
        <ng-template #loader>
            <app-student-edit-loader></app-student-edit-loader>
        </ng-template>
      

