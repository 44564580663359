import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { catchError, debounceTime, defaultIfEmpty, flatMap, map, mergeMap, Observable, of, startWith, switchMap, take, takeWhile, tap } from "rxjs";
import { ModelAction } from "src/app/common/components/sidemodalcontainer/sidemodalcontainer.component";
import { Iexpense } from "./interfaces/Iexpense";
import { loadExpense, SaveExpense, SelectExpense } from "./store/expense.action";
import { expenseState } from "./store/expense.reducer";
import { getExpenseState } from "./store/expense.selector";

@Component({
  selector: "app-expense",
  templateUrl: "./expense.component.html",
  styleUrls: ["./expense.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExpenseComponent extends ModelAction implements OnInit {
  firstload = true;
  expenseState$ = this.store.pipe(select(getExpenseState));

  constructor(
    private store: Store<expenseState>
  ) {
    super();
  }
 

  public pager$ = new Observable()
  private perpage = 5;

  ngOnInit() {
   
    this.store.dispatch(loadExpense());
  }

  onPaginate(page: number) {
    this.pager$
      .pipe(
       // tap(async (pager) => this.paginate(pager.page + page)),
        take(1),
        catchError((err) => of(err))
      )
      .subscribe();
  }
  private paginate(page: number) {
   // this.expenseService.getlist(page, this.perpage);
    this.firstload = false;
  }
  private editSelection(expense: Iexpense) {
    this.openModel("Edit Expense ");
  }
  onsubmit(expenseSubmited:Iexpense)
  {
     this.store.dispatch(SaveExpense({ expense: expenseSubmited }));
     this.closeModel()
  }
  selectExpense(expense:Iexpense)
  {
     this.store.dispatch(SelectExpense({expenseId:expense.id}));
     this.openModel("Edit Expense");
  }
  onCloseModel()
  {
    this.closeModel()
    this.store.dispatch(SelectExpense({ expenseId: "" }));
  }
  AddExpense() {
   // this.selectedsubject.next({} as Iexpense);
    this.store.dispatch(SelectExpense({ expenseId: "" }));
    this.openModel("Add Expense");
  }
}
