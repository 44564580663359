import { ChangeDetectionStrategy, Component
  , EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Istudent, StudentService } from 'src/app/pages/students/services/student.service';

@Component({
  selector: 'app-studentsearch',
  templateUrl: './studentsearch.component.html',
  styleUrls: ['./studentsearch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentsearchComponent implements OnInit {

  @Input() students: Istudent[] | undefined;
  @Output() studentFilter = new EventEmitter<string>()

  constructor(private studentService:StudentService) { }

  formfilter = new FormGroup({
    student: new FormControl("", [Validators.required]),
  });
  filterStudent() {
    if (!this.formfilter.get("student")?.valid) return;
    
    var id =this.formfilter.get("student")?.value
    console.log(id)
    this.studentFilter.emit(id?.toString());    
  }
  ngOnInit(): void {
  }

}
