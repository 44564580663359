import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "app-student-card",
  template: `<ng-container>
    <div class="card">
      <section>
        <app-studentregistration-captureimage
        (oncaptureimage)="captureImage($event)"
        >
        </app-studentregistration-captureimage>
      </section>
      <section class="card-info">
        <section class="card-header">
          <img
            src="../assets/images/clientlogo.png"
            alt="logo"
            height="50"
            width="90"
          />
          <span>HAPPY KIDS DAY CARE</span>
          <p class="address">No. 32 Cnr. Curie & Marlborough Road,</p>
          <p>Rembrandt Park</p>
          <p>PHONE: 27-82-341-1040/ 27-82-341-1010</p>
        </section>
        <section class="card-content">
          <div>
            <h3>Student Number: <span>{{studentinformation?.studentNumber}}</span></h3>
            <p>Name: <span>{{studentinformation?.name}} {{studentinformation?.surname}}</span></p>
            <p>Grade: <span>{{studentinformation?.grade}}</span></p>
          </div>
          <app-icons [icon]="badge" 
                     [animate]="true"
                    class_css="icon-l" ></app-icons>
        </section>
      </section>
    </div>
    <div class="form-control button-md">
         
      </div>
    <div class="form-control button-md grid col-4" style="margin: 0 auto;
width: 200px;">
         <button type="button"  class="greybtn" (click)="cancel()">Previous</button>
          <button type="button" (click)="proceed()" [disabled]="capturedimage === undefined">Next</button>
      </div>
  </ng-container>`,
  styleUrls: ["./student-card.component.scss"],
})
export class StudentCardComponent implements OnInit {

  @Input() studentinformation: any | undefined
  @Input() badge!: string
  @Output() oncancel= new EventEmitter<boolean>();
  @Output() oncontinue= new EventEmitter<any>();
  capturedimage:any
  
  captureImage($image: any) {
    console.log($image)
    this.capturedimage = $image
  }
  cancel()
  {
    this.oncancel.emit(true)
  }
  proceed()
  {
    this.oncontinue.emit(this.capturedimage);
  }
  constructor() {}

  ngOnInit(): void {}
}
