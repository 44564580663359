<div class="container minheight-70vh padding-4em">


    <h3 class="textalign-center right-margin-5em">Happy Kids Enrolment</h3>
    <app-step-breadcrumb [breadcrumbsprogress$]="stepprogress$"></app-step-breadcrumb>
    <h3 style="margin: 0 auto;width: fit-content;" *ngIf="stepprogress$ |async as step">
        {{step[formstep].title | uppercase}}
    </h3>

    <section class="" *ngIf="this.formstep ===2 || this.formstep ===3 ">
        <div class="form-control grid col-4-studentDerails" *ngIf="studentDetail !== undefined">
            <app-icons [icon]="selectedgrade" [title]="studentDetail.grade" class_css="icon" [animate]="true"></app-icons>

            <span name="studentNumber" *ngIf="studentDetail !== undefined
                                                                    && studentDetail.studentNumber !== undefined">
                Student Number:{{studentDetail.studentNumber}}
            </span>
            <h4 class="startdatesection_h4">Start Date:
                {{startdate.value | date : 'dd MMMM YYYY'}}
            </h4>
        </div>
    </section>
    <div [ngSwitch]="formstep">
        <div *ngSwitchCase="0">
            <form *ngIf="formstep ==0;" (ngSubmit)="nextstep()">
                <div class="form-control padding-2em">
                    <div class="grid col-4   icongrid grid gap-2em icongrid" *ngIf="students$| async as students">
                        <ng-container *ngIf="gradeState$ | async as gradestate">
                            <a *ngFor="let grade of gradestate.grades" [ngClass]="[grade?.classBadge,
                                                                                   (grade.classBadge === selectedgrade) ? 'active-'+ grade.classBadge :'']">
                                <app-icons [icon]="grade?.classBadge" [title]="grade.name" [animate]="true" class_css="icon-l" (selectIcon)="selectgrade($event,students)"></app-icons>
                            </a>
                        </ng-container>
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </div>
                <div class="form-control grid col-4 button-md padding-2em">
                    <span></span>
                    <button *ngIf="studentDetail !== undefined
                                                                        && studentDetail?.grade !== undefined;else invalidform" type="submit" class="greenbtn">
                        Next
                    </button>
                    <ng-template #invalidform>
                        <button type="button" disabled="true">
                            Select Grade
                        </button>
                    </ng-template>
                </div>
            </form>
        </div>
        <div *ngSwitchCase="1" class="grid" style="padding-top: 2em;">
            <section class="smallsection">
                <input type="date" [formControl]="startdate" value="{{datetowork | date:'yyyy-MM-dd'}}" name="startdate" min="{{mindate | date:'yyyy-MM-dd'}}" max="{{maxdate | date:'yyyy-MM-dd'}}"/>


                <div class="form-control grid col-4 button-md padding-2em">
                    <button type="button" (click)="previousstep()" class="greybtn">
                        Previous
                    </button>
                    <button type="button" (click)="onProceedstudentstartdate()" [disabled]="startdate.invalid">
                        Next
                    </button>
                </div>
            </section>

        </div>
        <ng-container *ngSwitchCase="2">
            <app-studentregistration-details [selectedgrade]="selectedgrade" [grade]="this.studentDetail.grade" [numberofStudents]="studentCount" (cancel)="previousstep()" (onproceedstep)="onProceedstudentdetail($event)"></app-studentregistration-details>
        </ng-container>
        <ng-container *ngSwitchCase="3">
            <app-studentregistration-gaurdian (onproceedstep)="onProceedIgaurdiandetail($event)" (cancel)="previousstep()"></app-studentregistration-gaurdian>
        </ng-container>
        <ng-container *ngSwitchCase="4">
            <section class="padding-2em">
                <app-student-card [badge]="selectedgrade" [studentinformation]="studentDetail" (oncancel)="previousstep()" (oncontinue)="onProceedstudentprofileImage($event)"></app-student-card>
            </section>
        </ng-container>
        <ng-container *ngSwitchCase="5">
            <app-studentregistration-view [badge]="selectedgrade" [student]="studentinformation" (cancelEnrollment)="previousstep()" (validateEnrollment)="enrollstudent($event)"></app-studentregistration-view>
        </ng-container>
        <div *ngSwitchDefault>
            Done
        </div>
    </div>
    <ng-container *ngIf="studentsState$ | async as studentState">
        <section *ngIf="studentState.newsaved && studentState.selected" class="overlay-modal">
            <div class="model-content">
                <a class="closebtn" [routerLink]="['/fee']" href="javascript:void(0)" routerLinkActive="router-link-active">X</a>
                <section class="header">
                    <h3 class="attention">
                        Registration Successful
                    </h3>
                </section>
                <section class="content">
                    <h3 class="invalid">
                        <span>
                            Registered Student:
                        </span>

                    </h3>
                    <p>Student Number:
                        <span>{{studentState.selected.studentNumber}}</span>

                    </p>
                    <p>Class
                        <span>{{studentState.selected.grade}}</span>
                    </p>
                    <p>Student Name:
                        <span>
                            {{studentState.selected.name}}

                            {{studentState.selected.surname}}
                        </span>
                    </p>
                    <section style="padding: 1em 0em 0em 0em;
                                        width: 50%;
                                        margin: 0 auto;">

                        <h5>Please Note
                        </h5>
                        <p style="font-size: 0.8rem;">

                            For a student to be active a registration fee is required. Click the pay now button to pay and activate the student!
                        </p>
                    </section>

                </section>
                <section class="action md-controls">
                    <a class="paynowbutton" [routerLink]="['/payment/',studentState.selected.id]" href="javascript:void(0)" routerLinkActive="router-link-active">Pay now</a>


                </section>
            </div>
        </section>
    </ng-container>

