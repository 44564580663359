<ng-container *ngIf="studentviewmodelService$.vm_$| async as vm_">
    <app-table [data]="vm_" css="min-width-280px">
        <ng-template #headers>
            <th colspan="2" class="textalign-center"> Summary</th>
        </ng-template>
        <ng-template #rows let-row>
            <td  [ngClass]="{'red-context':(row.value < 0)}">{{ row.key | pipeselection:(row.value < 0)}}</td>
            
            <td [ngClass]="{'red-context':(row.value < 0)}">{{ row.value | currency: "R" }}</td>
        </ng-template>
    </app-table>
</ng-container>