
    <h3 class="textalign-center margin-top-4em">Happy kids Student Master</h3>
<div class="container scrollpane-40vh padding-4em-sides margin-top-2em ">
    <div class=" grid col-3">
        <span></span>
       
    </div>
    <ng-container *ngIf="students">
        <app-table [data]="students" css="min-width-280px">
            <ng-template #headers>               
                <th class="textalign-center "> 
                    <form  [formGroup]="filterFormControl" style="width: 144px;" class="form-input">   
                        <span>
                            Student Number 
                        </span>
                        <input type="text" style="padding: .3rem;
                        margin: .3rem;"  (keyup)="onchangestudentnumber($event)">
                    </form>
                </th>
                <th class="textalign-center" >
                    <span> Grade </span>
                        <form [formGroup]="filterFormControl" 
                        *ngIf="gradestate$ |async as gradestates">
                        <select name = "grade" formControlName="grade" 
                        (change)="filter($event)"
                        style="margin: .3rem">
                          <option value="" >Select filter</option>
                            <option *ngFor="let grade of gradestates.grades"
                             [value]="grade.name">{{grade.name}}</option>
                        </select>
                </form> 
                </th>
                <th class="textalign-center"> 
                    <form  [formGroup]="filterFormControl" style="width: 144px;" class="form-input">   
                        <span>
                            Student Name
                        </span>
                        <input type="text" style="padding: .3rem;
                        margin: .3rem;"  (keyup)="onchangestudentname($event)">
                    </form>
                </th>
                <th class="textalign-center"> Parent Name</th>
                <th class="textalign-center"> Telephone</th>    
                <th class="textalign-center"> Action</th>   
 
            </ng-template>
            <ng-template #rows let-row>
                <td>{{row.studentNumber}}</td>
                <td>{{row.grade}}</td>
                <td>{{row.name}} {{row.surname}}</td>
                <td>{{row.gaurdianName}} {{row.gaurdianSurname}}</td>
                <td>{{row.mobileTelephone}}</td>

                <td class="td-control">
                <a class="" href="javascript:void(0)">
                     <span (click)="selectstudent(row.id)"> Edit
                </span>
               
                </a>
                </td>
            </ng-template>
        </app-table>
    </ng-container>

</div>
<app-sidemodalcontainer
             title=""
             [show]="(selectedstudent$ | async) != undefined">
    <ng-template #content>
   <app-student-edit 
   (cancel)="cancelstudentselection()"
   (submit)="onSubmit($event)"

   [student$]=selectedstudent$>

   </app-student-edit>
</ng-template>
</app-sidemodalcontainer>