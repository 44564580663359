import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { GradeComponent } from './grade/grade.component';
import { AuthGaurd } from '../../gaurds/auth.guard';
import { GradeEditComponent } from './grade-edit/grade-edit.component';


const routes: Routes = [

  {
    path:"grade",component:GradeComponent,

  },
  {
    path:"grade-add",component:GradeEditComponent 
  }
]
@NgModule({
  declarations: [	
   ],
  imports: [
    CommonModule
    ,RouterModule.forRoot(routes),
  ]
})
export class GradeRoutingModule { }
