import { createAction, props } from "@ngrx/store";
import { IGrade } from "../igrade.model";

export const loadGrade = createAction("[Grade Global] LoadGrades");
export const loadGradeSuceess = createAction(
  "[Grade Global] LoadGradesSucess",
  props<{ grades: IGrade[] }>()
);
export const loadGradeFail = createAction(
  "[Grade Global] LoadGradesFail",
  props<{ err: any }>()
);
