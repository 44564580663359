import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: "app-paymentform",
  template: `
    <form
      [formGroup]="paymentForm"
      class="form-input grid  col-2 "
      (ngSubmit)="onSubmit()"
      style="width: 600px;"
    >
      <input
        placeholder="Type amount to pay"
        class="custom-input"
        formControlName="fee"
        name="pay"
      />
      <section class="button-md">
        <button type="submit" [disabled]="!paymentForm.valid" class="">
          Pay
        </button>
      </section>
      <span
        style="height:22px;"
        *ngIf="
          paymentForm.dirty && paymentForm?.get('fee')?.hasError('required')
        "
      >
        {{ "Numberic value required!" }}
      </span>
      <span
        style="height:22px;"
        *ngIf="paymentForm?.get('fee')?.hasError('pattern')"
      >
        {{ "Value invalid, ensure to use only numeric values!" }}
      </span>
      <span
        style="height:22px;"
        *ngIf="paymentForm?.get('fee')?.hasError('max')"
      >
        {{ "Value entered is greater than outstanding balance!" }}
      </span>
      <span
        style="height:22px;"
        *ngIf="paymentForm.dirty && paymentForm?.get('fee')?.hasError('min')"
      >
        {{ "Value entered is less than required value!" }}
      </span>
    </form>
  `,
  styles: [
    `
      .form-control .form-input input {
        height: 38px;
        margin-right: 10px;
      }
      .button-md button:disabled {
        cursor: not-allowed;
        background-color: #92979a !important;
        /* min-width: 9rem !important; */
        color: #ccc !important;
      }
      .button-md button {
        min-width: 9rem !important;

        margin-left: 7px;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PaymentformComponent implements OnInit {
  @Input() defaultvalue: number = 0;
  @Input() maxvalue: number = 0;
  @Output() paymentevent: EventEmitter<any> = new EventEmitter();

  constructor() {}
  paymentForm = new FormGroup({
    fee: new FormControl("", [
      Validators.required,
      Validators.min(1),
      Validators.pattern(/^-?(0|[1-9]\d*)?$/),
    ]),
  });
  ngOnInit(): void {
    this.paymentForm.get("fee")?.addValidators(Validators.max(this.maxvalue));
    this.paymentForm.get("fee")?.patchValue(this.defaultvalue.toString());
  }
  onSubmit() {
    if (this.paymentForm.valid) {
      this.paymentevent.emit(this.paymentForm.get("fee")?.getRawValue());
      this.paymentForm.get("fee")?.patchValue("0");
    }
  }
}
