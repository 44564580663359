<ng-container *ngIf="studentFilter$  | async  as students">

<app-studentregistration-grid 
(filterGrade)="filterByGrade($event)"
(filterStudentNumber)="filterByStudentNumber($event)"
(filterStudentName)="filterByStudentName($event)"
(FormSubmit)="SaveStudent($event)"

[students]="students">
</app-studentregistration-grid>

<ng-container *ngIf="studentState$ | async"></ng-container>
<ng-container *ngIf="Onfilter$ |async"></ng-container>

</ng-container>

