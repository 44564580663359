export interface IGrade {
  Id: string;
  teacher: string;
  name: string;
  fees: number;
  classBadge: string;
  registration: number;
  teacherid: any;
}
export const responseGradeObject = (response: any) => {
  return {
    Id: response.id,
    teacher: response.teacher,
    name: response.name,
    fees: response.fees,
    classBadge: response.classBadge,
    registration: response.registration,
    teacherid: response.teacherid,
  } as IGrade;
};
export const toGradeArray = (gradeContainer: any) => {
  const grades = new Array<IGrade>();
  gradeContainer.items.forEach((grade_: any) => {
    var grade = {
      Id: grade_.id,
      teacher: grade_.teacher,
      name: grade_.name,
      fees: grade_.fees,
      classBadge: grade_.classBadge,
      registration: grade_.registration,
      teacherid: grade_.teacherid,
    } as IGrade;
    grades.push(grade);
  });
  return grades;
};
