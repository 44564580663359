import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InvoiceComponent } from '../invoicePage/invoice.component';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SharedModule } from 'src/app/common/shared.module';




@NgModule({
  declarations: [
    InvoiceComponent
  ],
  imports: [
    CommonModule,
    RouterModule, 
    ReactiveFormsModule,
    HttpClientModule,
    SharedModule
  ],
  exports:[InvoiceComponent]
})
export class InvoiceModule { }
