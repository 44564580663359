<div class="container minheight-70vh padding-2em">
    <section class="flex gap-2em">
        <div class="student-info-display">
            <div class="form-display">
                <span>
                    Grade:
                </span>
                <span>
                    {{student?.grade}}</span>
            </div>
            <div class="form-display">
                <span>Student Number:
                </span>
                <span>{{student?.studentNumber}}</span>
            </div>
        </div>
        <app-icons [icon]="badge" class_css="icon" [animate]="false"></app-icons>
    </section>

    <section>

        <div class="slim-title-bar grid col-2">
            Student Details
            <div>
                <img src="assets/images/checkbox.svg"></div>
            </div>
            <div class="form-control grid col-3">

                <div class="form-display">
                    <span>Name:
                    </span>
                    <span>{{student?.name}}</span>
                </div>
                <div class="form-display">
                    <span>Surname:
                    </span>
                    <span>{{student?.surname}}</span>
                </div>
                <div class="form-display">
                    <span>Id Number:</span>
                    <span>{{student?.iDNumber}}
                    </span>
                </div>
                <div class="form-display">
                    <span>Gender:
                    </span>
                    <span>{{student?.gender}}</span>
                </div>
            </div>
            <div class="slim-title-bar grid col-2">
                Contact Details
                <div>
                    <img src="../../../assets/images/checkbox.svg"></div>
                </div>

                <div class="form-control grid col-3">

                    <div class="form-display">
                        <span>address:
                        </span>
                        <span>{{student?.address}},
                            {{student?.postalCode}}</span>
                    </div>
                    <div class="form-display">
                        <span>Telephone:
                        </span>
                        <span>{{student?.telephone}}</span>
                    </div>
                    <div class="form-display">
                        <span>email:
                        </span>
                        <span>{{student?.email}}</span>
                    </div>
                </div>
            </section>
            <div class="slim-title-bar grid col-2">
                Parents/Guardian Details
                <div>
                    <img src="assets/images/checkbox.svg"></div>
                </div>
                <div class="form-control grid col-3">

                    <div class="form-display">
                        <span>Parent/Gaurdian Name:
                        </span>
                        <span>{{student?.gaurdianName}}</span>
                    </div>
                    <div class="form-display">
                        <span>Parent/Gaurdian Surname:
                        </span>
                        <span>{{student?.gaurdianSurname}}</span>
                    </div>
                    <div class="form-display">
                        <span>Relationship:
                        </span>
                        <span>{{student?.relationship}}</span>
                    </div>
                    <div class="form-display">
                        <span>Id Number:
                        </span>
                        <span>{{student?.gaurdianIdNumber}}</span>
                    </div>
                    <div class="form-display">
                        <span>Employer:
                        </span>
                        <span>
                            {{student?.employer}}
                        </span>
                    </div>
                    <div class="form-display">
                        <span>Employer Contact:
                        </span>
                        <span>{{student?.employeeContact}}</span>
                    </div>

                    <div class="form-display">
                        <span>Next Of Kin name:
                        </span>
                        <span>{{student?.nextOfKin}}</span>
                    </div>
                    <div class="form-display">
                        <span>Next Of Kin Number:
                        </span>
                        <span>{{student?.nextOfKinNumber}}</span>
                    </div>
                    <div class="form-display">
                        <span>Next Of Kin Relationship
                        </span>
                        <span>{{student?.nextOfKinRelation}}</span>
                    </div>
                </div>
                <div class="slim-title-bar grid col-2">
                    Medical Details
                    <div>
                        <img src="../../../assets/images/checkbox.svg"></div>
                    </div>
                    <div class="form-control grid col-3">

                        <div class="form-display">
                            <span>Illness:
                            </span>
                            <span>{{student?.illness}}</span>
                        </div>
                        <div class="form-display">
                            <span>Allergies:
                            </span>
                            <span>{{student?.allergies}}</span>
                        </div>
                        <div class="form-display">
                            <span>Medical Aid Group:
                            </span>
                            <span>{{student?.medicalAidGroup}}</span>
                        </div>
                        <div class="form-display">
                            <span>
                                Family Doctor:
                            </span>
                            <span>{{student?.familyDoctor}}</span>
                        </div>
                        <div class="form-display">
                            <span>
                                Doctor Contact:
                            </span>
                            <span>{{student?.familyDoctorNumber}}</span>
                        </div>
                    </div>
                    <div class="form-display">

                        <app-error-notify [issuccess]="false"></app-error-notify>
                    </div>
                    <div class="form-control placebottom  grid col-4 button-md">
                        <span></span>
                        <button type="button" class="greybtn" (click)="cancel()">
                            Back</button>
                        <button type="submit" class="greenbtn" (click)="verifyenrollment()">
                            Submit
                        </button>

                    </div>
                </div>

