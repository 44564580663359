<div class="grid_center_calc-wh place-item-center">
    <img src="/../../assets/images/adminmatomelogo.png" alt="">
    <form [formGroup]="loginform" (ngSubmit)="onSubmit()">
        <div class="form-control">
            <div class="form-input">
                <label for="username"></label>
                <input name="username" placeholder="Username" type="text" formControlName="username" required="required"/>
                <span *ngIf="!this.loginform.get('username')?.pristine
                 && !this.loginform.get('username')?.valid">
                    Invalid Username!
                </span>
            </div>
            <div class="form-input">
                <label for="username"></label>
                <input name="password" type="password" placeholder="Password" formControlName="password"/>
                <span *ngIf="!this.loginform.get('password')?.pristine
                                                                                        && !this.loginform.get('password')?.valid">
                    Invalid Password!
                </span>
            </div>
            <div class="form-control grid col-4 button-md">
                <button type="submit">
                    Login</button>
            </div>
        </div>
    </form>
    
    </div>
  
