import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ErrorNotifyComponent } from "./components/notifications/error-notify/error-notify.component";
import { LoaderComponent } from "./components/loader/loader.component";
import { SidemenuComponent } from "./components/sidemenu/sidemenu.component";
import { ApptableComponent } from "./components/apptable/apptable.component";
import { InitialsPipe, PipeselectionPipe } from "./pipes/pipeselection.pipe";
import { StudentsearchComponent } from "./components/studentsearch/studentsearch.component";
import { ReactiveFormsModule } from "@angular/forms";
import { GobackComponent } from "./components/goback/goback.component";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { MultistepbreadcrumbComponent } from "./components/multistepbreadcrumb/multistepbreadcrumb.component";
import { PopupComponent } from './components/popup/popup.component';
import { SidemodalcontainerComponent } from './components/sidemodalcontainer/sidemodalcontainer.component';
import { EmptyNaPipe } from './pipes/emptyna.pipe';
import { PaymentsummaryComponent } from "./components/paymentsummary/paymentsummary.component";
import { AppTablePaginationComponent } from "./components/apptable/apptablepagination.component";

@NgModule({
  declarations: [	
    ErrorNotifyComponent,
    LoaderComponent,
    SidemenuComponent,
    ApptableComponent,
    PipeselectionPipe,
    StudentsearchComponent,
    GobackComponent,
    MultistepbreadcrumbComponent,
    PaymentsummaryComponent,
    InitialsPipe,
    PopupComponent,
    SidemodalcontainerComponent,
    EmptyNaPipe,
    AppTablePaginationComponent
   ],
  exports: [
    ErrorNotifyComponent,
    LoaderComponent,
    SidemenuComponent,
    ApptableComponent,
    PipeselectionPipe,
    StudentsearchComponent,
    GobackComponent,
    MultistepbreadcrumbComponent,
    InitialsPipe,
    SidemodalcontainerComponent,
    EmptyNaPipe,
    PaymentsummaryComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, BrowserModule, RouterModule],
})
export class SharedModule {}
