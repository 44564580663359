export interface Iexpense {
[x: string]: any
id:string
name:string
quantity: number
reason:string
user:any
userprofiename:string
cost:number
attachment:any
category:string
created:any
update:any
}

export function toexpensArray(expense: any): Iexpense[] {
    var expenselist = new Array<Iexpense>();
    expense.items.forEach((expense: any) => {
      let newexpense = {
        id: expense.id,
        name: expense.name,
        reason: expense.reason,
        user: expense.user,
        userprofiename: expense.userprofiename,
        quantity: expense.quantity,
        cost: expense.cost,
        attachment: expense.attachment,
        category: expense.category,
        created: expense.created,
        update: expense.update,
      };
      expenselist.push(newexpense);
    });
    return expenselist;
  }
