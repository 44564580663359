import { state } from "@angular/animations";
import { Action, createFeatureSelector, createReducer, createSelector, on, select } from "@ngrx/store";
import { Istudent } from "../services/student.service";
import { loadStudentsFail, loadStudentSuccess, SaveStudent, SaveStudentFailed, SaveStudentSuccess, SelectStudent } from "./student.actions";

export interface StudentState {
  students: Istudent[];
  selected: Istudent | undefined;
  selectedStudent: Istudent;
  error: any | undefined;
  load: boolean;
  newsaved: boolean;
}

const initailState: StudentState = {
  error: undefined,
  students: [],
  selected: undefined,
  selectedStudent: {} as Istudent,
  load: false,
  newsaved: false,
};

const _studentReducer = createReducer(
  initailState,
  on(loadStudentSuccess, (state, studentContainer) => ({
    ...state,
    students: studentContainer.students,
    selectedStudent: state.students[0],
    error: undefined,
  })),
  on(loadStudentsFail, (state, errorContainer) => ({
    ...state,
    error: errorContainer.err,
  })),
  on(SaveStudent, (state) => ({
    ...state,
    load: true,
  })),
  on(SaveStudentSuccess, (state, studentContainer) => ({
    ...state,
    load: false,
    selected: studentContainer.student,
    newsaved: true,
  })),
  on(SaveStudentFailed, (state, errorContainer) => ({
    ...state,
    load: false,
    error: errorContainer.err,
  })),
  on(SelectStudent, (state, { studentId }) => ({
    ...state,
    students: state.students,
    selectedStudent: state.students[0],
    selected:state.students.filter(s=> s.id === studentId)[0],
    error:
      state.students.length < 1
        ? `State is empty, cannot filter ${studentId}`
        : undefined,
  }))
);
export function StudentReducer(state: any| undefined, action: Action)
{
    return  _studentReducer(state,action)
}


export const getStudentState = createFeatureSelector<StudentState>("students")

export const getSelectedStudent= createSelector(
  getStudentState,
  (state:StudentState)=>state.selected 
)

export const getStudents = createSelector(
  getStudentState,
  (state: StudentState) => state.students
);