import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from "@angular/core";
import { async, BehaviorSubject, catchError, concat, concatAll, concatMap, EMPTY, filter, flatMap, map, mergeMap, of, Subscription, switchMap, tap, toArray } from "rxjs";
import { Ipager } from "src/app/common/components/apptable/apptablepagination.component";
import { ModelAction } from "src/app/common/components/sidemodalcontainer/sidemodalcontainer.component";
import { GradeService } from "src/app/pages/grades/grade.service";
import { StudentService } from "src/app/pages/students/services/student.service";
import { Iteacher } from "../../interface/iteacher";
import { TeacherService } from "../../services/teacher.service";

@Component({
  selector: "app-teachergrid",
  templateUrl: "./teachergrid.component.html",
  styleUrls: ["./teachergrid.component.css"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeachergridComponent
  extends ModelAction
  implements OnInit, OnDestroy
{
  teachers$ = this.teacherservice.getlistObservable();
  teachers2$ = this.teacherservice.teacher$.pipe(
    mergeMap((a) => a),
    tap((t: any) => console.log(t)),
    switchMap((a: Iteacher, c) => this.gradeService.getgradeByteacher(a.name)),
    tap((t: any) => console.log(t.items))
  );
  pager$ = this.teacherservice.pagerObserverable$.pipe();

  selectedteacher$ = new BehaviorSubject<Iteacher>({} as Iteacher);

  subscribers = new Array<Subscription>();
  constructor(
    private teacherservice: TeacherService,
    private studentService: StudentService,
    private gradeService: GradeService
  ) {
    super();
  }
  ngOnDestroy(): void {
   this.subscribers.forEach(s=>s.unsubscribe())
  }
  ngOnInit() {
    //this.teacherservice.getlistObservable()
  }

  onformSubmit(teacher: Iteacher) {
    if (teacher.id === undefined) {
      const subscreate = this.teacherservice
        .create(teacher)
        .pipe(
          switchMap(
            (t) =>
              (this.teachers$ = this.teacherservice
                .getlistObservable()
                .pipe(tap(() => this.closeModel())))
          )
        )
        .subscribe();
      this.subscribers.push(subscreate);
      return;
    }

    const subs = this.teacherservice
      .update(teacher)
      .pipe(
        switchMap(
          (t) =>
            (this.teachers$ = this.teacherservice
              .getlistObservable()
              .pipe(tap(() => this.closeModel())))
        )
      )
      .subscribe();
    this.subscribers.push(subs);
  }

  filter($event: Event) {
    throw new Error("Method not implemented.");
  }
  addteacher() {
    this.selectedteacher$.next({} as Iteacher);
    this.openModel("Add Teacher ");
  }

  onSelectTeacher(teacherid: any) {
    const subscriber = this.teachers$
      ?.pipe(
        switchMap((teacherarray) => teacherarray),
        filter((e) => e.id == teacherid),
        tap((e) => {
          this.selectedteacher$.next(e);
        }),
        tap(console.log),
        flatMap(async (e) => this.editSelection(e))
      )
      .subscribe();
  }
  private editSelection(teacher: Iteacher) {
    this.selectedteacher$.next(teacher);
    this.openModel("Edit Teacher ");
  }
}
