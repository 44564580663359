import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { catchError, concatMap, map, mergeMap, of, switchMap, tap } from "rxjs";
import { ExpenseNewService } from "../services/expense-s.service";
import * as expenseAction from "./expense.action";

@Injectable()
export class ExpenseEffects {
  constructor(
    private expenseservice: ExpenseNewService,
    private action$: Actions
  ) {}

  loadExpenseItems$ = createEffect(() => {
    return this.action$.pipe(
      ofType(expenseAction.loadExpense),
      concatMap(() =>
        this.expenseservice.getall().pipe(
          map((expenseItem) =>
            expenseAction.loadExpenseSuccess({ Expenses: expenseItem })
          ),
          catchError(async (err) =>
            expenseAction.loadExpenseFailed({ err: err })
          )
        )
      )
    );
  });

  saveExpense$ = createEffect(() =>
    this.action$.pipe(
      ofType(expenseAction.SaveExpense),
      map((action) => action.expense),
      mergeMap((expense) =>
        this.expenseservice.add(expense).pipe(
          switchMap(expens =>  [
            expenseAction.SaveExpenseSuccess(),
            expenseAction.loadExpense()
          ]),
          catchError((error) =>
            of(expenseAction.SaveExpenseFailed({ err: error }))
          )
        )
      )
    )
  );

  // saveExpense = createEffect(() => {
  //   return this.action$.pipe(
  //     tap(console.log),
  //     ofType(expenseAction.SaveExpense),
  //     map((action) => action.expense),
  //     mergeMap((expense) =>
  //       this.expenseservice.add(expense).pipe(
  //         // tap(() => SaveExpenseSuccess()),
  //         map(() => expenseAction.SaveExpenseSuccess()),
  //         catchError(async (err) =>
  //           expenseAction.SaveExpenseFailed({ err: err })
  //         )
  //       )
  //     )
  //   );
  // });
}
