<form [formGroup]="teacherForm"
 (ngSubmit)="onSubmit()" >
<div class="form-control grid col-2 padding-top-2em " style="min-width: 40vw;">
  <div class="form-input ">
      <label for="name" >Name</label>
      <input placeholder="Enter Name"  
             type="text" 
             name="name" 
             id="name" 
             formControlName="name"/>
      <span *ngIf="teacherForm.get('name')?.dirty  && teacherForm.get('name')?.errors">
          Invalid Name!
      </span>
  </div>
  <div class="form-input">
      <label for="surname" >Surname</label>
      <input placeholder="Enter surname" 
             type="text" 
             name="surname" 
             id="surname" 
             formControlName="surname"/>
      <span *ngIf="teacherForm.get('surname')?.dirty &&  teacherForm.get('surname')?.errors">
          Invalid Surname!
      </span>
  </div>
  <div class="form-input">
      <label for="email" >
        Email
      </label>
      <input placeholder="Enter Email" 
             type="text" 
             name="email" 
             id="email" 
             formControlName="email"/>
      <span *ngIf="teacherForm.get('email')?.dirty &&
                   teacherForm.get('email')?.errors">
          Invalid Email!
      </span>
  </div>
  <div class="form-input">
      <!-- <label for="gender"
         *ngIf="teacherForm.get('gender')?.getRawValue()?.length > 0">
         gender</label>
         <select formControlName="gender">
          <option>Choose Gender</option>
          <option *ngFor="let gender of genders">{{ gender }}</option>
        </select>
      <span *ngIf="
            teacherForm.get('gender')?.dirty &&
            teacherForm.get('gender')?.errors">
          Invalid Gender!
      </span> -->
  </div>
  <div class="form-input">
      <label for="iDNumber" >ID Number</label>
      <input placeholder="Enter Id number" 
             type="text" 
             name="surname" 
             id="iDNumber" 
             formControlName="iDNumber"/>
      <span *ngIf="
              teacherForm.get('iDNumber')?.dirty &&
              teacherForm.get('iDNumber')?.errors">
          Invalid Id Number!
      </span>
  </div>
  <div class="form-input">
    <label for="mobileTelephone" >Mobile Telephone</label>
    <input placeholder="Enter Mobile Telephone"
           type="text" 
           name="mobileTelephone" 
           id="mobileTelephone" 
           formControlName="mobileTelephone"/>
    <span *ngIf="
          teacherForm.get('mobileTelephone')?.dirty &&
          teacherForm.get('mobileTelephone')?.errors">
        Invalid mobileTelephone!
    </span>
</div>
  <div class="form-input">
      <label for="address" >Address</label>
      <textarea placeholder="Address" type="text" name="address" id="address" formControlName="address"></textarea>
      <span *ngIf="
      teacherForm.get('address')?.dirty &&
      teacherForm.get('address')?.errors">
          Invalid Address!
      </span>
  </div>
  <section></section>
  <div class="form-input">
      <label for="postalCode">Postal code</label>
      <input placeholder="Postal code" type="text" name="postalcode" id="postalCode" formControlName="postalCode"/>
      <span *ngIf="
      teacherForm.get('postalCode')?.dirty &&
      teacherForm.get('postalCode')?.errors">
          Postal code!
      </span>
  </div>
</div>
<section class="model-action">
  <div class="form-control grid col-4 button-md">
    <div></div>
    <button 
      type="button"
      class="greybtn"
      style="margin-right: 2.5em;"
      (click)="onclose()">
      Close
    </button>
    <button type="submit"  
    [disabled]="!teacherForm.valid"
     style="padding: 0.5em 2em;" >submit</button>
  </div>
</section>
</form>
<app-error-notify [issuccess]="false"></app-error-notify>
