import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable, tap } from 'rxjs';

@Component({
  selector: 'app-expense-tile',
  template:`
  <Section class="grid expensecard"> 
      <span>
        {{title}}
      </span>
      <span *ngIf="expenseconst as total;else nototal">
      {{total | currency :"R"}}
      </span>
      <ng-template #nototal>
      <span >
      {{0 | currency :"R"}}
      </span>
      </ng-template>
    </Section>
  `,
  styleUrls: ['./expenseDisplaytile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpenseDisplaytileComponent implements OnInit {

  @Input() expenseconst: number| undefined;
  @Input() title =""
  constructor() { }

  ngOnInit() {
    
  }

}
