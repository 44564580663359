import { ErrorHandler, Inject, Injectable, InjectionToken, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from  '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SigninComponent } from './pages/auth/signin/signin.component';
import { UseLoginComponent } from './pages/auth/components/use-login/use-login.component';
import { HeaderComponent } from './common/components/header/header.component';
import { HomeComponent } from './pages/home/home.component';
import { ReactiveFormsModule } from '@angular/forms';
import { InputControlComponent } from './common/components/input-control/input-control.component';
import { StudentModuleModule } from './pages/students/student-module/student-module.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from './common/shared.module';
import { InvoiceModule } from './pages/invoice/invoiceModule/invoice.module';
import { GradesModule } from './pages/grades/grade.module';
import { GradeRoutingModule } from './pages/grades/grade-routing.module';
import { TeacherModule } from './pages/teachers/teacher.module';
import { ExpenseModule } from './pages/expense/module/expense.module';
import { ActionReducerMap, StoreModule } from '@ngrx/store';


import * as  expenseReducers from './pages/expense/store/expense.reducer';
import { EffectsModule } from '@ngrx/effects';
import * as  fromStudentReducers  from './pages/students/store/student.reducers';
import * as fromGradeReducers from './pages/grades/store/grade.reducers';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { PaymentsComponent } from './pages/payments/payments.component';
import * as fromPaymentReducer from './pages/payments/stores/payment.reducers';
import { PaymentModule } from './pages/payments/payment.module';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


export const reducers: ActionReducerMap<any> = {
  expenses: expenseReducers.expenseReducer,
  students: fromStudentReducers.StudentReducer,
  grades: fromGradeReducers.GradeReducers,
  payments: fromPaymentReducer.PaymentReducer,
};

@NgModule({
  declarations: [
    AppComponent,
    SigninComponent,
    UseLoginComponent,
    HeaderComponent,
    HomeComponent,
    //PaymentsComponent,
  ],
  imports: [
    BrowserModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    StudentModuleModule,
    SharedModule,
    InvoiceModule,
    GradesModule,
    AppRoutingModule,
    ExpenseModule,
    TeacherModule,
    PaymentModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: "School Admin",
      logOnly: !environment.production,
    }),
  ],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],

  bootstrap: [AppComponent],
})
export class AppModule {}

