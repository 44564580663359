import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { IGaurdiandetail } from '../../studentregistration/studentregistration.component';

@Component({
  selector: 'app-studentregistration-gaurdian',
  template: `
  <form class="form-max-control-70 margin-top-bottom-1em" 
            (ngSubmit)="onSubmit()" 
            [formGroup]="registrationParentForm">
                <div  class="title-bar grid col-2">
                    Parents/Guardian Details
                    <div> <img *ngIf="this.registrationParentForm.valid" 
                        src="assets/images/checkbox.svg"></div>
                </div>

                <div class="form-control grid col-3">
                    <div class="form-input">
                        <input placeholder="Parent/Gaurdian Name" type="text"
                        name="gaurdianName" formControlName="gaurdianName"/>
                        <span *ngIf="!this.registrationParentForm.pristine
                        && !this.registrationParentForm.get('gaurdianName')?.valid">  Invalid GaurdianName!
                    </span>
                    </div>
                    <div class="form-input">
                        <input placeholder="Parent/Gaurdian surname" type="text" 
                        name="gaurdianSurname" formControlName="gaurdianSurname"/>
                        <span *ngIf="!this.registrationParentForm.pristine
                        && !this.registrationParentForm.get('gaurdianSurname')?.valid">  Invalid GaurdianSurname!
                    </span>

                        
                    </div>
                    <div class="form-input">
                        <input placeholder="Relationship" name="relationship" formControlName="relationship"/>
                        <span *ngIf="!this.registrationParentForm.pristine
                        && !this.registrationParentForm.get('relationship')?.valid">  Invalid Relationship!
                    </span>
                    </div>
                </div>
                <div class="form-control grid col-3">
                    <div class="form-input">
                        <input placeholder="Employer" type="text" name="employer" formControlName="employer"/>
                    </div>
                    <div class="form-input"></div>
                    <div class="form-input">
                        <input placeholder="Id Number" name="gaurdianIdNumber" 
                        formControlName="gaurdianIdNumber"/>
                        <span *ngIf="!this.registrationParentForm.pristine
                        && !this.registrationParentForm.get('gaurdianIdNumber')?.valid">  Invalid IDNumber!
                    </span>
                    </div>
                </div>
                <div class="form-control grid col-3">
                    <div class="form-input">
                        <input placeholder="Employer Contact" type="text" 
                        name="employeeContact" formControlName="employeeContact"/>
                    </div>
                    <div></div>
                    <div class="form-input">
                        <input placeholder="Mobile Telephone" name="mobile" formControlName="mobileTelephone"/>
                        <span *ngIf="!this.registrationParentForm.pristine
                        && !this.registrationParentForm.get('mobileTelephone')?.valid">  Invalid MobileTelephone!
                    </span>
                    </div>
                </div>
                <div class="form-control grid col-3">
                    <div class="form-input">
                        <input placeholder="Next Of Kin name" type="text" name="nextOfKin" formControlName="nextOfKeen"/>
                    </div>
                    <div class="form-input">
                        <input placeholder="Next Of Kin Number" type="text" name="nextOfKinNumber" formControlName="nextOfKinNumber"/>
                    </div>
                    <div class="form-input">
                        <input placeholder="Relationship" name="nextOfKinRelation" formControlName="nextOfKinRelation"/>
                    </div>
                </div>
                <div class="title-bar grid col-2">
                    Medical Details
                    <div> <img src="assets/images/checkbox.svg"></div>
                </div>
                <div class="form-control grid col-3">
                    <div class="form-input">
                        <input placeholder="Illness" name="illness" formControlName="illness"/>
                    </div>
                    <div class="form-input">
                        <input placeholder="Allergies" name="allergies" formControlName="allergies"/>
                    </div>
                    
                </div>
                <div class="form-control grid col-3">
                    <div class="form-input">
                        <input placeholder="Medical Aid Group" name="medicalGroup" formControlName="medicalAidGroup"/>
                    </div>
                    <div class="form-input">
                        <input placeholder="Medical Aid Group Number" name="medicalAid" formControlName="medicalAidNumber"/>
                    </div>
                </div>
                <div class="form-control grid col-2-L">
                    <div class="form-input">
                        <input placeholder="Family Doctor" type="text" name="familyDoctor" formControlName="familyDoctor"/>
                    </div>

                    <div class="form-input">
                        <input placeholder="Doctor Contact" name="doctorContact" formControlName="doctorContact"/>
                    </div>
                </div>
                <div class="form-control grid col-4 button-md">
                    <span></span>
                    <button type="button" class="greybtn" (click)="onCancel()">
                    Previous</button>
                    <button 
                    [disabled]="registrationParentForm.invalid"
                    type="submit" class="greenbtn">
                        Next
                    </button>
                </div>
            </form>
  `,
  styleUrls: ['./studentregistration-gaurdian.component.scss']
})
export class StudentregistrationGaurdianComponent implements OnInit {

  constructor() { }
  registrationParentForm = new FormGroup({
    gaurdianName: new FormControl("", [
      Validators.required,
      Validators.minLength(3),
    ]),
    gaurdianSurname: new FormControl("", [
      Validators.required,
      Validators.minLength(3),
    ]),
    relationship: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
    ]),
    employer: new FormControl("", []),
    gaurdianIdNumber: new FormControl("", [
      Validators.minLength(8),
      Validators.required,
    ]),
    employeeContact: new FormControl("", []), //What about Passports
    mobileTelephone: new FormControl("", [
      Validators.required,
      Validators.minLength(5),
    ]),
    nextOfKeen: new FormControl("", []),
    nextOfKinNumber: new FormControl("", []),
    nextOfKinRelation: new FormControl("", []),
    illness: new FormControl("", []),
    allergies: new FormControl("", []),
    medicalAidNumber: new FormControl("", []),
    medicalAidGroup: new FormControl("", []),
    doctorContact: new FormControl("", []),
    familyDoctor: new FormControl("", []),
    familyDoctorNumber: new FormControl("", []),
  });
  @Output() onproceedstep= new EventEmitter<IGaurdiandetail>()
  @Output() cancel = new EventEmitter();
  ngOnInit(): void {
    
  }

  onSubmit(){
    const studentdetails= {...this.registrationParentForm.value} as IGaurdiandetail
    this.onproceedstep.emit(studentdetails)
  }

  onCancel(){
    this.cancel.emit()
  }

}
