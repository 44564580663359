import { trigger } from "@angular/animations";
import { Injectable } from "@angular/core";
import { ComponentStore, tapResponse } from "@ngrx/component-store";
import { catchError, concatMap, EMPTY, Observable } from "rxjs";
import { Payment, PaymentLedger } from "src/app/pages/payments/Payments.model";
import {
  months,
  PaymentLedgerService,
} from "src/app/pages/payments/services/payment.ledger.service";

export interface studentpaymentDetailsState {
  arrears: number | undefined;
  error: any | undefined;
  loading: boolean;
  studentId: string;
  ledger: PaymentLedger[];
  registrationPaid: boolean;
  totalPaid: number ;
  yearlyTotal: number;
  studentstartMonth: number;
}

const initialState: studentpaymentDetailsState = {
  arrears: undefined,
  error: undefined,
  loading: false,
  ledger: [],
  registrationPaid: false,
  studentId: "",
  studentstartMonth: -1,
  totalPaid: -1,
  yearlyTotal: 0,
};

@Injectable()
export class StudentPaymentDetailsStore extends ComponentStore<studentpaymentDetailsState> {
  constructor(private paymentService: PaymentLedgerService) {
    super(initialState);
  }

  readonly getstate$ = this.select((state) => {
    return { ...state };
  });

  readonly setloader = this.updater(
    (state: studentpaymentDetailsState, loader: boolean) => {
      return {
        ...state,
        loading: loader,
      };
    }
  );

  readonly setArrears = this.updater(
    (state: studentpaymentDetailsState, arrearsCalc: arrearsCalculation) => {
      return {
        ...state,
        arrears: this.getarrears(
          arrearsCalc.fee,
          arrearsCalc.total,
          arrearsCalc.startMonth
        ),
      };
    }
  );

  readonly updateError = this.updater(
    (state: studentpaymentDetailsState, err: any) => {
      return {
        ...state,
        error: err,
      };
    }
  );
  readonly setPaymentTotal= this.updater(
    (state: studentpaymentDetailsState, total: number) => {
      return {
        ...state,
        totalPaid: total,
      };
    }
  );

  readonly addPaymentledger = this.updater(
    (state: studentpaymentDetailsState, ledger: PaymentLedger[]) => {
      console.log(ledger);
      return {
        ...state,
        ledger: ledger,
       // totalPaid: this.getTotalpaid(state.ledger),
      };
    }
  );

  readonly loadPaymentInformation = this.effect(
    (studentId$: Observable<string>) => {
      return studentId$.pipe(
        concatMap((id: string) => {
          this.setloader(true);
          return this.paymentService.getpaymentsObserverable(id).pipe(
            tapResponse(
              (ledgerPayments) => {
                this.setloader(false);
                  const payments = this.getpaymentLedger(
                    ledgerPayments
                  ) as PaymentLedger[];

                this.addPaymentledger(payments);

                this.setPaymentTotal(this.getTotalpaid(payments));
              },
              (e: string) => this.updateError(e)
            ),
            catchError(async (error) => this.updateError(error))
          );
        })
      );
    }
  );

  readonly getTotalpaid = (payments: any[]) => {
    if (payments.length > 0) {
      var amountsPaid = payments.map(({ amount }) => amount ?? 0);
      console.log(payments);
      const totpaid = amountsPaid.reduce((pay, val) => pay + val) as number;
      return totpaid;
    }
    return 0;
  };
  getstartmonth = (startdate: Date | undefined) => {
    if (startdate !== undefined) return new Date(startdate).getMonth();
    return -1;
  };
  readonly getpaymentLedger = (payments: Payment[]) => {
    const payments_history = payments.filter((a) => a.type !== "r_fees");

    return months.map((month) => {
      if (payments_history.length > 0) {
        return {
          ...payments_history?.filter((a) => a.month === month.index)[0],
          month,
        } as PaymentLedger;
      }
      return { ...[], month };
    });
  };
  getarrears = (
    fee: number | undefined,
    totalpaid: number,
    startMonth: number
  ) => {
    if (fee !== undefined) {
      const todatemonth = new Date().getMonth() + 1 - startMonth;
      const todatefee = fee * todatemonth;
      const arrears = totalpaid - todatefee;
      return arrears;
    }
    return 0;
  };
  getyearlypayment = (fees: number | undefined, startmonth: number) => {
    if (fees !== undefined)
      return fees * months.filter((a) => a.index > startmonth).length;
    return 0;
  };
}

export interface arrearsCalculation{
fee: number,
total:number,
startMonth: number
}