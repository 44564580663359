import { Injectable } from '@angular/core';
import { combineLatest, map, tap } from 'rxjs';
import { GradeService } from 'src/app/pages/grades/grade.service';
import { PaymentService } from './payment.service';
import { StudentService } from './student.service';

@Injectable({
  providedIn: 'root'
})
export class StudentviewmodelService {

  private vm$original = combineLatest(this.paymentService.studenttotal$,
    this.gradeservice.selectedgrade$.pipe(map(a=> {return {key:"Yearly Fee_Payable",
     value: this.getyearlypayment(a.fees )}})),
)
vm_$ = combineLatest([
    this.vm$original.pipe(map(a=> {return a[0]})),
    this.vm$original.pipe(map(a=> {return a[1]})),
    this.vm$original.pipe(map(a=> {return { key:"Outstanding_balance", value:a[1].value - a[0].value}})),
    this.vm$original.pipe(map(a=> {return { key:"Arrears | Credit", 
    value:this.getarrears(this.gradeservice.staticgrade?.fees??0,a[0].value ) }})),
])

constructor(private paymentService:PaymentService,
  private gradeservice: GradeService,
  private studentService: StudentService) {
 }

private getstartmonth():number{
  if(this.studentService.studentstatic?.startdate === undefined) throw new Error("student start date required")
 
  return new Date(this.studentService.studentstatic?.startdate).getMonth() 
   
}
private getyearlypayment(fees:number):number{
  return fees * this.paymentService.months.filter(a=>a.index>this.getstartmonth()).length
}

private getarrears(fee:number, totalpaid: number):number{  
  const todatemonth =new Date().getMonth()+1 - this.getstartmonth()
  this.paymentService.generateSummary
  const todatefee = fee * todatemonth
  const arrears = totalpaid - todatefee
  return arrears
}

private getarrears2(fee:number, totalpaid: number):number{
  if(this.studentService.studentstatic?.startdate === undefined) throw new Error("student start date required")
  const todatemonth = new Date(this.studentService.studentstatic?.startdate).getMonth() 
  
  const todatefee = fee * todatemonth
  const arrears = totalpaid - todatefee
  return arrears
}
}
