import { HttpClient } from "@angular/common/http";
import { Injectable, OnInit } from "@angular/core";
import {
  catchError,
  Observable,
  of,
  Subject,
  subscribeOn,
  Subscription,
  tap,
  throwError,
} from "rxjs";
import { BehaviorSubject } from "rxjs/internal/BehaviorSubject";
import { LoaderService } from "src/app/common/services/loader.service";
import { NotifiyService } from "src/app/common/components/notifications/notifiy.service";
import { environment } from "src/environments/environment";
import PocketBase, { User } from "pocketbase";
import { DatabaseService } from "src/app/common/services/database.services";

@Injectable({
  providedIn: "root",
})
export class AuthService implements OnInit {
  private userSubject = new BehaviorSubject<any>(null);
  public user$ = this.userSubject.asObservable();

  private loggedinSubject = new BehaviorSubject<boolean>(false);

  public isloggedIn$ = this.loggedinSubject.asObservable();

  public getAuthStatus() {
    return this.client.authStore.isValid;
  }

  constructor(
    private databaseservice: DatabaseService,
    private notifiyService: NotifiyService,
    private loaderService: LoaderService
  ) {
    try {
      if (
        this.client.authStore.token != null &&
        this.client.authStore.isValid
      ) {
        this.userSubject.next(this.client.authStore.model);

        this.loggedinSubject.next(this.client.authStore.isValid);
      }
    } catch (err: any) {
      console.error(err?.message);
    }
  }
  ngOnInit(): void {}

  subscribtion = new Subscription();

  private AuthUser(user_: any | undefined) {
    this.userSubject.next(user_);

    this.loggedinSubject.next(true);
    this.notifiyService.createNotification({
      success: true,
      message: `welcome ${user_?.profile.name}!`,
    });
  }
  public authenticate(username: string, password: string) {
    this.loaderService.setloader(true);
    this.client.users
      .authViaEmail(username, password)
      .then((user) => {

        console.log(user)
        this.AuthUser(user.user);

        this.loaderService.setloader(false);
      })
      .catch((err: ClientResponseError) => {
        this.loaderService.setloader(false);
        this.createErrorNotification(err.status);
      });
  }
  createErrorNotification(statusCode: number) {
    var messag_tousr = "";
    switch (statusCode) {
      case 500:
        messag_tousr = "Service Error";
        break;
      case 400:
        messag_tousr = "Authentication failed!";
        break;
      default:
        messag_tousr = "Service Unavailable!";
        break;
    }
    this.notifiyService.createNotification({
      success: false,
      message: messag_tousr,
    });
  }
  client = this.databaseservice.getclient();

  public logout() {
    this.client.authStore.clear();
    this.loggedinSubject.next(false);
  }

  private handleError(error: any) {
    false;
    this.loaderService.setloader(false);

    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }

    var messag_tousr = "";
    switch (error.status) {
      case 500:
        messag_tousr = "Service Error";
        break;
      case 400:
      default:
        messag_tousr = "Authentication failed!";
        break;
    }

    this.notifiyService.createNotification({
      success: false,
      message: messag_tousr,
    });
    return throwError(() => {
      return errorMessage;
    });
  }
}
export interface user {
  user: any;
  token: String;
}

export interface ClientResponseError {
  url: string; // requested url
  status: number; // response status code
  data: any; // the API JSON error response
  isAbort: boolean; // is abort/cancellation error
  originalError: Error | null; // the original non-normalized error
}
