import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyna'
})
export class EmptyNaPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if(value.length>0)
        return value;
    return "N/A"
  }

}
