import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { DatabaseService } from "./database.services";

@Injectable({
  providedIn: "root",
})
export class FileService {
  constructor(public database: DatabaseService) {}

  savefile(file:any
    , studentid:string
    , filename: string)
  {
    var data  = new FormData();

    var vewfile=dataURLtoFile(file,`${filename}.jpg`)

    data.append("images",vewfile)
    data.append("student",studentid)
    return this.database.getclient()
            .records
            .create("studentprofile",data)
  }

}
export function dataURLtoFile(dataurl:any, filename:string) {
  
 //if(dataurl === null || dataurl.length < 1) return;
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}

