import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { LoaderService } from '../../common/services/loader.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeComponent implements OnInit,OnDestroy {

  constructor(private loaderService:LoaderService) { 
    
  }
  ngOnDestroy(): void {
    this.loaderService.setsidebar(true)
  }

  ngOnInit(): void {
    this.loaderService.setsidebar(false)
  }

}
