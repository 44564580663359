<div class="container minheight-70vh grid col-2-auto justify-items-end">

    <section>
        <ng-container *ngIf="vm$ | async as vm">

            <app-registrationfeeblockscreen
             [student]="vm.student" 
             [showpop]="!vm.hasregistered" 
             [requiredFee]=vm.grade.registration></app-registrationfeeblockscreen>
            <h3 class="margin-1em-sides textalign-center margin-top-4em" *ngIf="vm.student">
                <p>
                    {{vm.student.studentNumber}}_{{vm.student.name }}
                    {{vm.student.surname}}</p>
                <span>({{vm.grade.name}})</span>
            </h3>

            <app-payment-grid
             [studentpayments]="vm.studentpayments" 
             [gradeFee]="vm.grade.fees" 
             [startMonth]="startMonth">
            </app-payment-grid>
        </ng-container>
    </section>
    <section class="padding-4em">
        <app-table [data]="[studentStartdate]" css="min-width-280px margin-top-bottom-1em ">
            <ng-template #headers>
                <th>Student Information</th>
            </ng-template>
            <ng-template #rows let-row>
                <tr>
                    <td>Start Date:
                        {{row |date : 'dd MMMM YYYY'}}</td>
                </tr>

            </ng-template>
        </app-table>
        <app-paymentsummary></app-paymentsummary>
    </section>
    <section class="form-control grid col-2 padding-2em  form_fix_bottom">
        <app-paymentform  (paymentevent)="onSubmit($event)"></app-paymentform>
        <section class="grid place-item-center red-context">
            <button (click)="error =''" class="custom-close" *ngIf="error.length > 0">X</button>
            <span>
                {{error}}
            </span>
        </section>
    </section>
</div>

