import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Istudent } from '../../services/student.service';


@Component({
  selector: 'app-studentregistration-view',
  templateUrl: './studentregistration-view.component.html',
  styleUrls: ['./studentregistration-view.component.scss']
})
export class StudentregistrationViewComponent implements OnInit {

  constructor() { }
  
  @Input() student: Istudent | undefined ={} as Istudent
  @Input() badge: string =""
  @Output() validateEnrollment= new EventEmitter<boolean>()
  @Output() cancelEnrollment= new EventEmitter<boolean>()
  ngOnInit(): void {
  }
  verifyenrollment()
  {
    this.validateEnrollment.emit(true);
  }
  cancel()
  {
    this.cancelEnrollment.emit(false)
  }

}
