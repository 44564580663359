import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GradeService } from '../grade.service';
import { IGrade } from '../igrade.model';

@Component({
  selector: 'app-grade-edit',
  templateUrl: './grade-edit.component.html',
  styleUrls: ['./grade-edit.component.scss']
})
export class GradeEditComponent implements OnInit {

  constructor(private gradeService: GradeService) { }
  grades:{ icon: string; title: string; }[]  = [
    {icon:"bee",title:"Bumble Bee"},
    {icon:"bear",title:"Bunny Bears "},
    {icon:"jellyfish",title:"Jelly Fish "},
    {icon:"bag",title:"Grade R "},
    {icon:"bells",title:"Jingle Bells "}
  ]
  gradeform = new FormGroup({
    name: new FormControl("",Validators.required),
    fees: new FormControl("",[
      Validators.required,
      Validators.pattern(/^\$?(\d{1,3}(\,\d{3})*|(\d+))(\.\d{0,2})?$/)
    ]),
    teacher: new FormControl("",[]),
    classBadge: new FormControl("")
  })
  formstep = 1;
  totalsteps=2
  ngOnInit(): void {
  }

  selectbadge(slectedbadge:any)
  {
    console.log(slectedbadge)
    this.gradeform.patchValue({"classBadge":slectedbadge.icon})
  }
  onSubmit()
  {
    if(this.gradeform.valid)
    {
      if(this.formstep === this.totalsteps)
      {
        const grade ={ ...this.gradeform.value } as unknown as IGrade
        this.gradeService.CreateGrade(grade)
        return;
      }
      this.formstep++;
    }
  }
}
