import { ChangeDetectionStrategy, Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { Ipager } from './apptablepagination.component';

@Component({
  selector: "app-table",
  template: `
    <table class="{{ css }}">
      <thead style="position: sticky; top: 0; z-index: 99;">
        <tr>
          <ng-container
            *ngTemplateOutlet="
              headers || defaultHeaderTemplate;
              context: { $implicit: data }
            "
          ></ng-container>
        </tr>
      </thead>
      <tbody *ngIf="data.length > 0; else tbodynoResults">
        <tr *ngFor="let row of data">
          <ng-container
            *ngTemplateOutlet="
              rows || defaultRowTemplate;
              context: { $implicit: row }
            "
          ></ng-container>
        </tr>
      </tbody>
      <ng-template #tbodynoResults>
        <tbody>
          <tr>
            <td>No Data</td>
          </tr>
        </tbody>
      </ng-template>
      <tfoot style="">
        <tr *ngIf="pager.totalPages>0">
          <td colspan="100%">
            <app-table-pagination
              [totalrows]="pager.totalItems"
              [page]="pager.page"
              [perpage]="pager.perpage"
              [totalPages]="pager.totalPages"
              (pagenav)="pagenav($event)"
            ></app-table-pagination>
          </td>
        </tr>
      </tfoot>
    </table>

    <!-- If no template is provided use keys as headers and display all values -->
    <ng-template #defaultHeaderTemplate let-data>
      <th *ngFor="let header of data[0] | keyvalue">{{ header.key }}</th>
    </ng-template>
    <ng-template #defaultRowTemplate let-row>
      <td *ngFor="let row of row | keyvalue">{{ row.value }}</td>
    </ng-template>
  `,
  styleUrls: ["./apptable.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ApptableComponent implements OnInit {
  constructor() {}
  @Input() data!: any[];
  @Input() css: string = "min-width-40em";
  @Output() onpage = new EventEmitter<number>();
  @Input() pager: Ipager = {
    page: 1,
    perpage: 1,
    totalItems: 0,
    totalPages: 0,
  };

  @ContentChild("headers") headers: TemplateRef<any> | undefined;
  @ContentChild("rows") rows: TemplateRef<any> | undefined;

  ngOnInit(): void {}
  pagenav(nav: number) {
    this.onpage.emit(nav);
  }
}
