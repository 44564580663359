import { state } from "@angular/animations";
import { createReducer, on, Action } from "@ngrx/store";
import { Iexpense } from "../interfaces/Iexpense";
import {
  loadExpense,
  loadExpenseFailed,
  loadExpenseSuccess,
  SaveExpense,
  SaveExpenseFailed,
  SaveExpenseSuccess,
  SelectExpense,
} from "./expense.action";

export interface expenseState {
  expense: Iexpense[];
  selected: Iexpense | undefined;
  error: any | undefined;
  totalMonthyExpense: number;
  totalExpense: number;
  loadSaving:boolean
}

const initialState: expenseState = {
  expense: [],
  selected: undefined,
  error: undefined,
  totalMonthyExpense: 0,
  totalExpense: 0,
  loadSaving:false
};

const gettotalExpense = (expenseList: Iexpense[]) => {
  return expenseList.reduce(
    (total: number, price: { cost: number }) =>
      Number(total) + Number(price.cost),
    0
  );
};
const getCurrentMonthExpense = (expenseList: Iexpense[]) => {
  const currentMonth = new Date().getMonth();
  const expenseMonthly = expenseList
    ?.filter((e) => new Date(e.created).getMonth() === currentMonth)
    .reduce(
      (total: any, price: { cost: any }) => Number(total) + Number(price.cost),
      0
    );
  return expenseMonthly;
};

const _expenseReducer = createReducer(
  initialState,
  on(loadExpense, (state, _) => ({
    ...state,
    error: undefined,
    expense: [],
  })),
  on(loadExpenseSuccess, (state, expenselist) => ({
    ...state,
    expense: expenselist.Expenses,
    totalExpense: gettotalExpense(expenselist.Expenses),
    totalMonthyExpense: getCurrentMonthExpense(expenselist.Expenses),
  })),
  on(loadExpenseFailed, (state, err) => ({
    ...state,
    error: err.err,
  })),
  on(SelectExpense, (state, selection) => ({
    ...state,
    selected: state.expense.filter((e) => e.id === selection.expenseId)[0],
  })),
  on(SaveExpenseFailed, (state, err) => ({
    ...state,
    error: err.err,
    loadSaving: false,
  })),
  on(SaveExpenseSuccess, (state) => ({
    ...state,
    loadSaving: false,
  })),
  on(SaveExpense, (state, {}) => ({
    ...state,
    loadSaving: true,
  }))
);

export function expenseReducer(state: any | undefined, action: Action) {
  return _expenseReducer(state, action);
}
