<div class="header-bg">
    <img class="logo" 
        *ngIf=" !(isloggedIn$ | async);else clientlogo" 
        src="/assets/images/privatecodelogo.png"
        style="max-width: 15em;"
        >
    <ng-template #clientlogo>
        <div class="header-profiler">
<section class="grid place-item-center col-2 justify-items-center" style="cursor: pointer;" routerLink="/">

                <img class="logo" src="assets/images/clientlogo.png">
                <h2 style="font-family: monospace;font-size: 1.2rem;">Management Cloud</h2>
            </section>
            <!-- add profile component -->
            <section class="section grid col-3 place-item-center gap-2em">
                <div class="grid col-2 gap-ms place-item-center" *ngIf="authUser$| async as user">
                    <img src="/assets/images/user.svg" class="filtersvg-pc_color" style="width:1.5em;margin-bottom: 5px;">
<span style="font-size: 1rem;width: max-content;margin-top: 2px;text-align: center;" > Hi {{user.profile.name}}
</span>

                </div>
                <span class="logout" (click)="logout()">
                    <img class="filtersvg" src="../../../assets/images/power.svg">
                </span>
            </section>
        </div>
    </ng-template>
</div>
<app-loader></app-loader>

