<div class="container scrollpane-40vh padding-4em-sides margin-top-4em">
    <h3 class="textalign-center margin-top-2em">Happy kids Expense Master</h3>

    <ng-container *ngIf="expenseState$ | async as expensestate">

        <section class="grid tile-display">
            <app-expense-tile title="Total Cost" [expenseconst]="expensestate.totalExpense"></app-expense-tile>

            <app-expense-tile title="Monthly Cost" [expenseconst]="expensestate.totalMonthyExpense"></app-expense-tile>

        </section>

        <app-expense-grid
        [expenselist]="expensestate.expense" 
        (onSelectExpense)="selectExpense($event)"
        (onCreateExpense)="AddExpense()"
       
         (paginate)="onPaginate($event)"></app-expense-grid>

        <ng-container *ngIf="expensestate.error">
            Error loading  expense
        </ng-container>

        <app-sidemodalcontainer [title]="title" [show]="showmodel">
            <ng-template #content>
                <app-expense-edit (cancelform)="onCloseModel()"
                              (onSubmitform)="onsubmit($event)" 
                              [expense]="expensestate.selected "
                              [loader]="expensestate.loadSaving">
            </app-expense-edit>
            </ng-template>
        </app-sidemodalcontainer>

    </ng-container>
</div>

