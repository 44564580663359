import { Injectable } from "@angular/core";
import { map, Observable, of } from "rxjs";
import { DatabaseService } from "src/app/common/services/database.services";
import { Iexpense, toexpensArray } from "../interfaces/Iexpense";

@Injectable()
export class ExpenseNewService {
  constructor(private databaseservice: DatabaseService) {}
  getall(page: number = 1, perpage: number = 10): Observable<Iexpense[]> {
    return this.getexpenslist(page, perpage).pipe(
      map((expenselistresponse) => toexpensArray(expenselistresponse))
    );
  }

  public add(expense: Iexpense) {
    const data = this.createformdata(expense);

    console.log(data)
    
    if (expense.id !== '') {
      return this.databaseservice.updateObservable(
        "expenses",
        expense.id,
        data
      );
    }
    return this.databaseservice.createObservable("expenses", data);
  }
  public update(expense: Iexpense) {
    const data = this.createformdata(expense);
    this.databaseservice.update("expenses", expense.id, data);
  }
  private getexpenslist(
    page: number = 1,
    perpage: number = 1
  ): Observable<any> {
    return this.databaseservice.getlistObservable(
      "expenses",
      page,
      perpage,
      {sort:"-created"}
    );
  }

  private createformdata(expense: Iexpense): FormData {
    const user = this.databaseservice.getactiveUser() as any;
    console.log(user);
    const expenseUser: { user: any; userprofiename:string} = {
      user: undefined,
      userprofiename: ""
    };
    expenseUser.user = user.id;
    expenseUser.userprofiename = user.profile.name;

    var data = new FormData();
    data.append("id", expense.id);
    data.append("name", expense.name);
    data.append("user", expenseUser.user);
    data.append("quantity", expense.quantity.toString());
    data.append("category", expense.category);
    data.append("reason", expense.reason);
    data.append("cost", expense.cost.toString());
    data.append("userprofiename", expenseUser.userprofiename);
    data.append("attachment", expense.attachment);

    return data;
  }
}
