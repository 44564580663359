
<div class="container 
        minheight-70vh 
        padding-4em
        icongrid grid
        gap-2em
         col-4 ">

  <a *ngFor="let grade of grades" [ngClass]=grade?.icon >
    <app-icons [icon]=grade?.icon [title]=grade.title class_css="icon-l"></app-icons>
  </a>
</div>




