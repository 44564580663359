import { createAction, props } from "@ngrx/store";
import { Payment } from "../Payments.model";

export const loadPaymentHistory = createAction(
  "[Payment Component] Load Payment",
  props<{ studentId: string }>()
);
export const loadPaymentHistorySuccess = createAction(
  "[Payment Component] Load Payment Success",
  props<{ Payments: Payment[],studentId:string }>()
);
export const loadPaymentHistoryFailed = createAction(
  "[Payment Component] Load Payment Success",
  props<{ err: any }>()
);
export const FeePayment = createAction(
  "[Payment Component] Fee Payment",
  props<{ feePayment: Payment }>()
);

