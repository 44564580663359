import { Component, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, distinctUntilChanged, filter, Subject, switchMap, take, tap } from "rxjs";
import { Istudent } from "../services/student.service";
import * as fromStudentAction from "../store/student.actions";
import { getStudentState } from "../store/student.reducers";

@Component({
  selector: "app-studentlist",
  templateUrl: "./studentlist.component.html",
  styleUrls: ["./studentlist.component.css"],
})
export class StudentlistComponent implements OnInit {
  private studentnameFilter = "StudentName";
  private studentNumberFilter = "StudentNumber";
  private gradeFilter = "Grade";

  private studentFilter = new BehaviorSubject<Istudent[]>([]);
  studentFilter$ = this.studentFilter.asObservable();

  Onfilter = new BehaviorSubject<{ filterType: string; searchTerm: string }>({
    filterType: "",
    searchTerm: "",
  });

  Onfilter$ = this.Onfilter.asObservable().pipe(
    distinctUntilChanged(),
    switchMap(({ filterType, searchTerm }) =>
      this.studentState$.pipe(
        tap(({ students }) => {
          if (searchTerm !== "") {
            if (filterType === this.gradeFilter) {
              const filtered = students.filter(
                (student) => student.grade === searchTerm
              );
              this.studentFilter.next(filtered);
              return;
            }
            if(filterType === this.studentnameFilter)
            {
              const filtered = students.filter(
                (student) =>
                  student.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ||
                  student.surname.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
              );
              this.studentFilter.next(filtered);
              return;
            }
              if (filterType === this.studentNumberFilter) {
                const filtered = students.filter((student) =>
                  student.studentNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
                );
                this.studentFilter.next(filtered);
                return;
              }
          }
          this.studentFilter.next(students);
        })
      )
    )
  );
  studentState$ = this.store.pipe(select(getStudentState)).pipe(
    tap((studentstate) => {
      this.studentFilter.next(studentstate.students);
    })
  );
  
  constructor(private store: Store) {
    store.dispatch(fromStudentAction.loadStudents());
  }

  filterByStudentNumber(studentNumber:string)
  {
    this.Onfilter.next({ filterType: this.studentNumberFilter, searchTerm: studentNumber });
  }

  filterByStudentName(studentname: string) {
    this.Onfilter.next({ filterType: this.studentnameFilter, searchTerm: studentname });
  }

  filterByGrade(grade: string) {
    // console.log(grade);
    this.Onfilter.next({ filterType: this.gradeFilter, searchTerm: grade });
  }
  SaveStudent(studentForm:Istudent)
  {
    console.log(studentForm.name);
        // this.store.dispatch(
          
        //   fromStudentAction.SaveStudent({ student: studentForm })
        // );
        // this.store.dispatch(fromStudentAction.loadStudents());
  }
  ngOnInit() {}
}
