<div class="full-height bg-main sidebar" *ngIf="loaderService.sidebarSubject$ | async">
    <a class="grid textalign-center menu-item" routerLink="/registration" >
        <img src="/assets/images/registration.png"/>
        <span>
            Register Student</span>
    </a>
    <a class="grid textalign-center menu-item"  [routerLink]="['/invoice']">
        <img src="/assets/images/invoice.png"/>
        <span>
            Invoice/Statements</span>
    </a>

    <a class="grid textalign-center menu-item">
        <img src="/../assets/images/learning.png"/>
        <span>
            AfterCare</span>
    </a>
    <a class="grid textalign-center menu-item">
        <img src="../assets/images/playground-.png"/>
        <span>
            Excursions/Occasions</span>
    </a>
    <a class="grid textalign-center menu-item" [routerLink]="['/fees']">
        <img src="/../assets/images/click.png"/>
        <span>
            Fees</span>
    </a>
    <a class="grid textalign-center menu-item" [routerLink]="['/expense']" 
    routerLinkActive="router-link-active">
        <img src="/../assets/images/expense.png"/>
        <span>
            Expenses</span>
    </a>
    <a class="grid textalign-center menu-item" [routerLink]="['/students']" routerLinkActive="router-link-active" >
        <img src="/../assets/images/students.png"/>
        <span>
            Students</span>
    </a>
    <a class="grid textalign-center menu-item">
        <img src="/../assets/images/dashicon.png"/>
        <span>
            Dashboard</span>
    </a>
</div>
