<section class="main-invoice full-width">
    <section class="invoice-header grid col-3-slim-center place-content-baseline">
        <div class="invoice-address-withlogo-top grid">
            <img src="assets/images/clientlogo.png" alt="clientlogo"/>
            <span>
                HAPPY KIDS DAY CARE
            </span>
            <span>
                No. 32 Cnr. Curie & Marlborough Road, Rembrandt Park
            </span>

            <span>
                PHONE: 27-82-341-1040
            </span>
            <span>
                PHONE: 27-82-341-1010
            </span>
        </div>
        <div class="invoice-type grid">
            <h2>Statement</h2>
        </div>
        <section class="invoice-customer-section grid">
            <div>
                <ng-container *ngIf="studentService.students$ |async as students">
                    <app-studentsearch class="grid margin-left-15" (studentFilter)="filterStudent($event)" [students]="students"></app-studentsearch>
                </ng-container>
                <div class="invoice-action-buttons"></div>
                <div class="invoice-payment-summary ">
                    <table *ngIf="summary_student$ | async as studentmash">
                    <tr *ngIf="!setSendStatement;else sendstatement">
                            <td></td>
                            <td class="invoice-action-buttonsiconbtn-display iconbtn-display">
                                <button class="iconbtn">
                                    <img src="/assets/images/print.svg" alt="print.svg">
                                </button>
                            <button class="iconbtn" (click)="setSendStatement = true">
                                    <img src="/assets/images/envelope.svg" alt="email.svg">
                                </button>
                            </td>
                        </tr>
                        <ng-template #sendstatement>
                        <tr>
                            
                            <td colspan="2" style="text-align: right;">
                                <p>Are you sure you want to send a statement to
                                <b>{{studentmash[1]?.email}}</b></p>
                                <div>
                                    <button class="sendNotification send" (click)="sendStatement(studentmash[1]?.id)">
                                        Send
                                    </button>

                                    <button class="sendNotification cancel" (click)="cancelSendStatement()">
                                        Cancel 
                                    </button>
                                </div>
                            </td>
                        </tr>
                        </ng-template>
                        <tr>
                            <td class="align-right padding-rt-4 padding-grid">
                                Student Number
                            </td>
                            <td class="border-pad-summary">
                                {{studentmash[1]?.studentNumber}}
                            </td>

                        </tr>
                        <tr>
                            <td class="align-right padding-rt-4 padding-grid">
                                Student Start Date
                            </td>
                            <td class="border-pad-summary">
                                <span *ngIf="studentmash[1]?.startdate !== null">
                                    {{studentmash[1]?.startdate! |date : 'dd MMMM YYYY'}}
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td class="align-right padding-rt-4 padding-grid">
                                Invoice Date
                            </td>
                            <td class="border-pad-summary">
                                {{today |date : 'dd MMMM YYYY'}}
                            </td>
                        </tr>
                        <tr>
                            <td class="align-right padding-rt-4 padding-grid">
                                {{ studentmash[0][3].key
                                 | pipeselection:(studentmash[0][3].value  < 0)}}
                            </td>
                            <td class="border-pad-summary">
                                {{studentmash[0][3].value | currency :"R"}}
                            </td>
                        </tr>
                        <tr *ngIf="studentmash[0][3].value  < 0">
                            <td class="align-right padding-rt-4 padding-grid">
                                Payment Due Date
                            </td>
                            <td class="border-pad-summary">
                                {{today |date : 'dd MMMM YYYY'}}
                            </td>
                        </tr>
                    </table>

                </div>
            </div>
        </section>
    </section>
    <section class="title-bar grid col-2">
        <span>
            BILL TO
        </span>
    </section>
    <section class="invoice-address-to grid col-2">
        <div class="grid place-content-baseline padding-right-sm gap-ms">
            <ng-container *ngIf="studentService.studentSelected$ | async as student">

                <span>{{student.gaurdianName}}
                    {{student.gaurdianSurname}}</span>
                <span>
                    {{student.address}}
                </span>
            </ng-container>

        </div>
        <div class="grid place-content-end padding-right-ms">
            <span>
                Date:
                {{today | date:"dd MMMM YYYY" }}
            </span>
        </div>

    </section>
    <ng-container *ngIf="summary_school$ |async as summary_schoolfees">
        <section class="invoice-details padding-top-2em">
            <app-table [data]="['']" css="full-width textalign-center ">
                <ng-template #headers>
                    <th colspan="" class="textalign-center">
                        Class</th>
                    <th colspan="" class="textalign-center">
                        Description</th>
                    <th colspan="" class="textalign-center">
                        Charge</th>
                    <th colspan="" class="textalign-center">
                        Payment recieved</th>
                    <th colspan="" class="textalign-center">
                        Amount Outstanding</th>
                </ng-template>
                <ng-template #rows let-row>
                    <td class="padding-2em">{{summary_schoolfees[0].name}}
                    </td>
                    <td>{{summary_schoolfees[1][1].key}}
                    </td>
                    <td>{{summary_schoolfees[1][1].value | currency :"R"}}
                    </td>
                    <td>{{summary_schoolfees[1][0].value | currency :"R"}}
                    </td>
                    <td>{{summary_schoolfees[1][2].value | currency :"R"}}
                    </td>
                </ng-template>
            </app-table>
        </section>
    </ng-container>

    <section class="index-foot padding-top-2em grid col-2-left-80">
        <app-table [data]="['a']" css="full-width">
            <ng-template #headers>
                <th colspan="" class="textalign-center"></th>
            </ng-template>

            <ng-template #rows let-row>
                <td [ngClass]="{'red-context':(row.value < 0)}">
                    <p class="disclaimer">
                        We would like to thank all of our parents who pay their fee accounts in full, and on time, each month. As you know, Happy Kids Day Care fees policy states that all fees are payable within each consecutive month, from January to December, with no parent going a month into arrears. Any parent contravening the School’s fee code, will be requested to leave Happy Kids Day Care. No child who has left the school, due to unpaid fees, will be allowed to return, irrespective of arrears payment.
                    </p>
                </td>

            </ng-template>
        </app-table>
        <div class="grid">
                <img style="width: 150px;" src="https://happykidsresources.pages.dev/happykids/sig.png" alt="sig"/>
            <span class="signature"></span>
        </div>
    </section>
</section>

